import axios from "axios";

axios.defaults.withCredentials = false;


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: "http://192.168.29.104:4003/api/v1/",
  // baseURL: "http://127.0.0.1:4000/api/v1/",
  crossDomain: false,
});

// Interceptor to update headers with the latest token
const updateTokenInterceptor = axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["x-auth-token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to remove the token interceptor
const removeTokenInterceptor = () => {
  axiosInstance.interceptors.request.eject(updateTokenInterceptor);
};

export { axiosInstance, removeTokenInterceptor };
