import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { ChangePassword, Delete, Logout } from './modals';
import AddAdmin from '../../pages/AdminManagement/modal/AddAdmin';
import AddVendor from '../../pages/Vendor/modal/VendorAdd';
import AddCustomer from '../../pages/Customer/modal/CustomerAdd';
import LedgerUpdate from '../../pages/Customer/modal/LedgerUpdate';
import TaxAdd from '../../pages/Tax/modal/TaxAdd';
import TaxDetails from '../../pages/Tax/modal/TaxDetails';
import ViewAdmin from '../../pages/AdminManagement/modal/ViewAdmin';
import UploadProduct from '../../pages/PurchaseOrder/modal/UploadProduct';
import PaymentModal from '../../pages/PurchaseOrder/modal/PaymentModal';
import BillModal from '../../pages/PurchaseOrder/modal/BillModal';
import UomAdd from '../../pages/Uom/modal/UomAdd';
import InvoiceAdd from '../../pages/SalesOrder/modal/InvoiceModal';
import StatusChange from '../../pages/SalesOrder/modal/StatusChange';

export default function CommonModal({ handleClose, showNotification, setShowNotification, modalOpen, isLoading, handelClick, formik, files, setFiles, data, categoryData, stateList }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
      className={modalOpen.class}
    >
      {modalOpen.currentComponent === 'changePassword' && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {['delete', 'status', 'changepassword'].includes(modalOpen.currentComponent) && (
        <Delete handleClose={handleClose} modalOpen={modalOpen} handelClick={handelClick} />
      )}
      {modalOpen.currentComponent === 'logout' && (
        <Logout handleClose={handleClose} handelClick={handelClick} modalOpen={modalOpen} />
      )}

      {modalOpen.currentComponent === 'addAdmin' && (
        <AddAdmin data={data} handleClose={handleClose} formik={formik} modalOpen={modalOpen} files={files} setFiles={setFiles} />
      )}
      {modalOpen.currentComponent === 'viewAdmin' && (
        <ViewAdmin data={data} handleClose={handleClose} formik={formik} modalOpen={modalOpen} files={files} setFiles={setFiles} />
      )}

      {modalOpen.currentComponent === 'vendorAdd' && (
        <AddVendor data={data} handleClose={handleClose} formik={formik} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'customerAdd' && (
        <AddCustomer data={data} handleClose={handleClose} formik={formik} modalOpen={modalOpen} />
      )}

      {modalOpen.currentComponent === 'editLedger' && (
        <LedgerUpdate data={data} handleClose={handleClose} formik={formik} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'taxAdd' && (
        <TaxAdd data={data} handleClose={handleClose} isLoading={isLoading} formik={formik} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'taxDetail' && (
        <TaxDetails data={data} handleClose={handleClose} isLoading={isLoading} formik={formik} modalOpen={modalOpen} />
      )}

      {modalOpen.currentComponent === 'uploadProduct' && (
        <UploadProduct data={data} handleClose={handleClose} formik={formik} modalOpen={modalOpen} files={files} setFiles={setFiles} />
      )}
      {modalOpen.currentComponent === 'payment' && (
        <PaymentModal data={data} handleClose={handleClose} isLoading={isLoading} formik={formik} modalOpen={modalOpen} />
      )}

      {modalOpen.currentComponent === 'bill' && (
        <BillModal data={data} handleClose={handleClose} isLoading={isLoading} formik={formik} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'uom' && (
        <UomAdd data={data} handleClose={handleClose} isLoading={isLoading} formik={formik} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'invoice' && (
        <InvoiceAdd data={data} showNotification={showNotification} setShowNotification={setShowNotification} handleClose={handleClose} isLoading={isLoading} formik={formik} modalOpen={modalOpen} />
      )}

      {modalOpen.currentComponent === 'orderStatus' && (
        <StatusChange data={data} showNotification={showNotification} setShowNotification={setShowNotification} handleClose={handleClose} isLoading={isLoading} formik={formik} modalOpen={modalOpen} />
      )}
    </Dialog>
  );
}
