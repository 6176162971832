import { deleteRequest, get, post, put } from "../web.request";

export const changePassword = async (id, data) => {
  return await put(`/changePassword/${id}`, data);
}

export const profileDetail = async (id) => {
  return await get(`/profileDetail/${id}`);
}

export const profileUpdate = async (id, data) => {
  return await put(`/updateProfile/${id}`, data);
}