import { Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BackButton, Heading } from '../../components/Common'
import { Box } from '@mui/system'
import { CompanyDetail } from '../../Service/Company.service'
import { useLocation } from 'react-router-dom'

const CompanyDetails = () => {

  const { state } = useLocation()
  const [companyData, setCompanyData] = useState({})
  const [isLoading, setIsLoading] = useState(true);

  const getCompanyData = async (id) => {
    const response = await CompanyDetail(id)
    if (response && response.data) {
      setCompanyData(response.data)
      setIsLoading(false)
    }
    else {
      setCompanyData({})
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (state && state?.id) {
      getCompanyData(state?.id)
    } else {
      setIsLoading(false)
    }
  }, [])
  const renderContentRow = (label, value) => (
    isLoading ? (
      <Skeleton animation="wave" variant="text" width="100%" height={30} />
    ) : (
      <Stack flexDirection={'row'}>
        <Typography fontWeight={500} fontSize={'18px'} color={'#999'} width={'200px'}>
          {label}
        </Typography>
        <Typography fontWeight={500} fontSize={'18px'}>
          {value}
        </Typography>
      </Stack>
    )
  )

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <BackButton />
        <Heading head='Company Details' />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className='card_container' p={4}>
            {renderContentRow("Company Name", companyData?.companyName)}
            {renderContentRow("Email", companyData?.email)}
            {renderContentRow("Phone Number", companyData?.phoneNo)}
            {renderContentRow("Vendor Name", companyData?.vendorName)}
            {renderContentRow("GST Number", companyData?.gstNo)}
            {renderContentRow("Pan No", companyData?.panNo)}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Heading smallHead='Location' />
          <Box className='card_container' p={4}>
            {renderContentRow("Address", companyData?.address1)}
            {renderContentRow("Address 2", companyData?.address2)}
            {renderContentRow("State", companyData?.stateId?.name)}
            {renderContentRow("City", companyData?.cityId?.name)}
            {renderContentRow("Zip Code", companyData?.zipcode)}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default CompanyDetails