import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Box, Switch, Chip } from '@mui/material'
import { Button, Heading, MenuButton, Modal } from '../../components/Common'
import { useNavigate } from 'react-router-dom'
import { Pagination, Search } from '../../components/Common/Table'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import { IoAdd, IoEyeOutline } from 'react-icons/io5'
import { purchaseOrderListData, purchaseOrderPayment } from '../../Service/Purchase.service'
import { invoiceList } from '../../Service/invoice.service'
import moment from 'moment'
import { VscFilter } from 'react-icons/vsc'
import InvoiceFilter from './InvoiceFilter'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import { filterInvoiceInitialValues, paymentSalesInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { AiOutlineEdit } from 'react-icons/ai'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { purchaseOrderPaymentValidationSchema } from '../../helper/validation'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'
import { TbMoneybag } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import { vendorDropdown } from '../../Service/Vendor.service'
import { salesOrderPayment } from '../../Service/payment.recieved'


const SalesInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [purchaseOrderData, setInvoiceData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [customerData, setCutomerData] = useState([]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const getCustomerData = async () => {
    const response = await vendorDropdown();
    if (response && response.data) {
      setCutomerData(response.data);
    } else {
      setCutomerData([]);
    }
  }

  useEffect(() => {
    getCustomerData();
  }, []);
  const getInvoiceList = async () => {
    setInvoiceData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await invoiceList(query);
    if (res?.status) {
      setInvoiceData(res?.data);
      formik.setFieldValue('formPrice', res?.minPrice);
      formik.setFieldValue('toPrice', res?.maxPrice);
      value.length === 0 &&
        setValue([res?.minPrice, res?.maxPrice]);

      setTotalData(res?.total);
    } else {
      setInvoiceData(null);
    }
  }


  const formik = useFormik({
    initialValues: filterInvoiceInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getInvoiceList();
    },
  });

  const formik1 = useFormik({
    initialValues: paymentSalesInitialValues,
    onSubmit: async (value) => {
      const payload = {
        "orderId": isModalOpen.id,
        "paymentType": value.paymentType,
        "paymentAmount": value.paymentAmount,
        "paymentDate": value.paymentDate,
      }

      const res = await salesOrderPayment(payload);

      if (res?.status) {
        setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
        dispatch(notificationSuccess({
          message: res?.message || t('Payment_Done_Successfully'),
          show: true,
          type: 'success',
        }))

        getInvoiceList();
      } else {
        dispatch(notificationSuccess({
          message: res?.message || t('PaymentFailed'),
          show: true,
          type: 'error',

        }))
      }
    },
    validationSchema: purchaseOrderPaymentValidationSchema
  });


  useEffect(() => {
    getInvoiceList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'saleDate':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('saleDate')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('saleDate')
        break;
    }
  };

  const paymentStatusMapping = {
    0: { label: t("Open"), color: "default" },
    1: { label: t("DueToday"), color: "warning" },
    2: { label: t("Overdue"), color: "error" },
    3: { label: t("PartiallyPaid"), color: "info" },
    4: { label: t("Paid"), color: "success" }
  };

  const PaymentStatusCell = ({ paymentStatus }) => {
    // Get the status object from the mapping or default to "Unknown"
    const status = paymentStatusMapping[paymentStatus] || { label: "Unknown", color: "default" };

    return (
      <TableCell className='table-hover'>
        <Chip label={status.label} color={status.color} />
      </TableCell>
    );
  };

  return (
    <div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Heading head={t('SalesInvoice')} />
      </Stack>
      <Stack mb={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Search search={search} setSearch={setSearch} setPage={setPage} />
        <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
          <Button buttonName={<VscFilter />} style={{ padding: '10px 11px' }} onClick={toggleDrawer} color='white' size='small' />
          <InvoiceFilter openDrawer={openDrawer} toggleDrawer={toggleDrawer}
            formik={formik}
            customerData={customerData}
          />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell style={{ cursor: 'pointer' }}>{t("InvoiceNo")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("OrderNo")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("CustomerName")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("Status")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("PayableAmount")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("DueAmount")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("DueDate")}</TableCell>


              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('saleDate', sortDate)}>
                <Stack flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
                  <div>{t("SaleDate")}</div>
                  {sortDate ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </Stack>
              </TableCell>
              <TableCell>{t("Action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOrderData && purchaseOrderData.length > 0 && purchaseOrderData?.map((data, i) => {
              return (
                <TableRow key={i} onClick={() => navigate('/view-invoice', {
                  state: {
                    id: data?.orderId,
                  }
                })}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell className='table-hover' >{data?.invoiceNumber}</TableCell>
                  <TableCell className='table-hover' >{data?.orderNumber}</TableCell>
                  <TableCell className='table-hover' >{data?.customername}</TableCell>
                  <PaymentStatusCell paymentStatus={data?.paymentStatus} />
                  <TableCell className='table-hover' >{data?.payableAmount}</TableCell>
                  <TableCell className='table-hover' >{data?.dueAmount}</TableCell>
                  <TableCell>{moment(data?.dueDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>{moment(data?.saleDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className='chatDropDown'>
                        <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content className='chatDropDownContent' align='end'>
                        <DropdownMenu.Item className='chatDropDownMenu' onClick={() => navigate('/view-invoice', {
                          state: {
                            id: data?.orderId,
                          }
                        })}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><IoEyeOutline style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>{t("View")}</Box></Stack>
                        </DropdownMenu.Item>
                        {data?.dueAmount > 0 && (<DropdownMenu.Item className='chatDropDownMenu' onClick={() => {
                          formik1.setFieldValue('dueAmount', data?.dueAmount);
                          setIsModalOpen({ open: true, currentComponent: "payment", head: t("Recived_A_Payment"), para: t("Are_you_sure_you_want_to_Make_A_Payment?"), id: data?.orderId, amount: data?.dueAmount })
                        }}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><TbMoneybag style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>{t("MakeAPayment")}</Box></Stack>
                        </DropdownMenu.Item>)}
                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              )
            })}

            {purchaseOrderData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              purchaseOrderData && purchaseOrderData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {
        // Conditionally render Pagination component if purchaseOrderData is not null
        purchaseOrderData != null && (
          <Pagination
            totalData={totalData} // Total number of data items
            page={page} // Current page number
            setPage={setPage} // Function to update current page number
            rowsPerPage={rowsPerPage} // Number of rows per page
            setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
          />
        )
      }
      <Modal
        modalOpen={isModalOpen} // Indicates whether the modal is open or closed
        // handelClick={changeStatus} // Handler function to change the status of the modal
        handleClose={() => // Handler function to close the modal and reset its state
          setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
        }
        formik={formik1}
      />
    </div >
  )
}

export default SalesInvoice