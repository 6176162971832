import { get, post, put } from "../web.request";

export const uomList = async (query) => {
  return await get(`/uomList${query}`);
}

export const changeUomStatus = async (id) => {
  return await put(`/statusChangeUom/${id}`);
}

export const uomDetail = async (id) => {
  return await get(`/uomDetail/${id}`);
}

export const uomUpdate = async (id, data) => {
  return await put(`/updateUom/${id}`, data);
}

export const uomCreate = async (data) => {
  return await post(`/addUom`, data);
}

export const uomDropdown = async () => {
  return await get(`/uomDropdown`);
}