import { Box, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, DateRange, Heading, Select } from '../../../components/Common';
import { customerDropdownForReport, customerPaymentMadeReport } from '../../../Service/Report.service';
import { useTranslation } from 'react-i18next';
import { notificationSuccess } from '../../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const PaymentReceived = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState(" ");
  const [customerData, setCustomerData] = useState([])
  const [fromData, setFromData] = useState('')
  const [reportData, setReportData] = useState([])
  const [toData, setToData] = useState('')
  const [amount, setAmount] = useState({
    payableAmount: 0,
    dueAmount: 0
  })

  const handleChange = (e) => {
    setCustomer(e.target.value)
  }

  const getCustomer = async () => {
    const res = await customerDropdownForReport();
    if (res.status) {
      setCustomerData(res.data)

    }
    else {
      setCustomerData([])
    }
  }

  const getSalesInvoiceReport = async () => {
    const selectQry = `?customerId=${customer}&fromDate=${fromData != "" ? moment(fromData).format('YYYY-MM-DD') : ""}&toDate=${toData != "" ? moment(toData).format('YYYY-MM-DD') : ""}`
    const res = await customerPaymentMadeReport(selectQry);
    if (res.status) {
      console.log('res.data', res)
      setAmount({
        payableAmount: res.totalPayableAmount,
        dueAmount: res.amount
      })
      setReportData(res.data)
    }
    else {
      setReportData([])
    }
  }

  useEffect(() => {
    getSalesInvoiceReport()
  }, [customer, fromData, toData])

  const paymentStatusMapping = {
    0: { label: t("Open"), color: "default" },
    1: { label: t("DueToday"), color: "warning" },
    2: { label: t("Overdue"), color: "error" },
    3: { label: t("PartiallyPaid"), color: "info" },
    4: { label: t("Paid"), color: "success" }
  };

  const PaymentStatusCell = ({ paymentStatus }) => {
    // Get the status object from the mapping or default to "Unknown"
    const status = paymentStatusMapping[paymentStatus] || { label: "Unknown", color: "default" };

    return (
      <TableCell className='table-hover'>
        <Chip label={status.label} color={status.color} />
      </TableCell>
    );
  };


  const downloadReport = async () => {
    const selectQry = `?customerId=${customer}&fromDate=${fromData != "" ? moment(fromData).format('YYYY-MM-DD') : ""}&toDate=${toData != "" ? moment(toData).format('YYYY-MM-DD') : ""}`

    const res = await customerPaymentMadeReport(selectQry);
    if (res) {

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'SalesByCustomer.xlsx');
      document.body.appendChild(link);
      link.click();

      dispatch(notificationSuccess({
        message: "Report Downloaded Successfully",
        show: true,
        type: "success"
      }))

    } else {
      dispatch(notificationSuccess({
        message: "Report Downloaded Failed",
        show: true,
        type: "error"
      }))
    }
  }

  useEffect(() => {
    getCustomer()
  }
    , [])

  return (
    <Box>
      <Stack mb={2}>
        <Heading head='Sales by Customer' />
        <Stack flexDirection={'row'} justifyContent={'space-between'} mt={1}>
          <Stack flexDirection={'row'} gap={1}>
            {/* <Search /> */}
            <Select
              selectList={customerData}
              style={{ width: '200px' }}
              selectHead={t('None')}
              value={customer}
              onChange={(e) => handleChange(e)} />
            <DateRange
              fromDate={fromData}
              setFromDate={setFromData}
              toDate={toData}
              setToDate={setToData}
            />
          </Stack>
          <Button buttonName='Export Report' size='small' onClick={() => {
            downloadReport()
          }} />
        </Stack>
      </Stack>
      <Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Customer Name </TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Status </TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Balance Due</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData && reportData.map((item, index) => {
                console.log('item', item)
                return (
                  <TableRow onClick={() => navigate('/view-invoice', {
                    state: {
                      id: item?.orderId,
                    }
                  })}>
                    <TableCell>{item.customername}</TableCell>
                    <TableCell>{item.invoiceNumber.invoiceNumber}</TableCell>
                    <PaymentStatusCell paymentStatus={item?.paymentStatus} />

                    <TableCell>{item.invoiceNumber}</TableCell>
                    <TableCell>{item.totalPaidAmount}</TableCell>
                    <TableCell>{item.dueAmount}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            {
              reportData.length === 0 ?
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4} align="center">No Data Found</TableCell>
                  </TableRow>
                </TableBody>
                : <TableHead>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>

                    <TableCell></TableCell>

                    <TableCell>{amount.payableAmount}</TableCell>
                    <TableCell>{amount.dueAmount}</TableCell>
                  </TableRow>
                </TableHead>
            }


          </Table>
        </TableContainer>
      </Stack>
    </Box>
  )
}

export default PaymentReceived