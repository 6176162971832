import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import { useTranslation } from 'react-i18next'

const LedgerUpdate = ({ handleClose, formik, modalOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Edit Ledger Name
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} width={{ sm: '400px' }} paddingY={1}>

          <Grid item xs={12} sm={12}>
            <Input labelinput={t('Name')}
              name='name'
              formik={formik}
              maxLength={50}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
        <Button buttonName={t('Save')}
          size='small' onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  )
}

export default LedgerUpdate