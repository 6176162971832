import { get, post } from "../web.request";

export const BillList = async (query) => {
  return await get(`/purchaseBillList${query}`);
}

export const addBill = async (id, data) => {
  return await post(`/addPurchaseBill/${id}`, data);
}

export const billDetail = async (id) => {
  return await get(`/purchaseBillDetail/${id}`);
}