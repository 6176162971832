import React, { useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import { useTranslation } from 'react-i18next'

const UploadProduct = ({ handleClose, formik, data, modalOpen, files, setFiles }) => {
  const { t } = useTranslation();
  const valid = true
  const [taxData, setTaxData] = useState([]);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Upload Product
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: '900px' }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <Typography fontSize={{ xs: '13px', sm: '16px' }} fontWeight={500} mb={'2px'}>{t("Upload Excel")}</Typography>
            <FileUpload files={files} setFiles={setFiles} singleImage={true}
              //accept only excel files and csv
              accept=".xls,.xlsx,.csv" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography fontSize={{ xs: '13px', sm: '16px' }} fontWeight={500} mb={'2px'}>Product List</Typography>
            <Table stickyHeader aria-label="simple table" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
              {/* <TableHead>
                <TableRow>
                  <TableCell style={{ cursor: 'pointer' }}>Product</TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>Price</TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>Tax</TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>Quantity</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                {[1, 1, 1, 1, 1, 1].map(() => {
                  return (
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                      <TableCell style={{ cursor: 'pointer' }}><Input placeholder={t('Product')} variant="standard" /></TableCell>
                      <TableCell style={{ cursor: 'pointer' }}><Input placeholder={t('Price')} variant="standard" /></TableCell>
                      <TableCell style={{ cursor: 'pointer' }}><Select selectList={taxData} selectHead='None' placeholder={t('Tax')} variant="standard" /></TableCell>
                      <TableCell style={{ cursor: 'pointer' }}><Input placeholder={t('Quantity')} variant="standard" /></TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>
                        <Typography fontSize={{ xs: '13px', sm: '16px' }} sx={{ color: valid ? '#03810e' : '#f10000' }} fontWeight={500} mb={'2px'}>{valid ? 'valid' : 'invalid'}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
        <Button buttonName={t('Upload')} size='small' onClick={handleClose} />
      </DialogActions>
    </>
  )
}

export default UploadProduct