import { useSelector } from "react-redux";
import { store } from "../state/Store";

export const IsRead = (pageName, user) => {
  // const user = useSelector((state) => state?.root?.auth)
  const elementIndex =
    user && user?.permission?.findIndex((item) => item.pageName == pageName);

  // if (user && user?.role && user?.role != 1) {


  if (elementIndex == -1) {
    return false;
  } else {
    if (
      user &&
      user.permission &&
      user.permission[elementIndex] && user.permission[elementIndex]?.readP == 1
    ) {
      console.log("dsdsdsdsdsds", user.permission[elementIndex])
      return true;
    } else {
      return false;
    }
  }
  // }
  // else {
  //   return true;
  // }
};

export const IsAdd = (pageName) => {
  const user = useSelector((state) => state?.root?.auth)

  const elementIndex =
    user && user?.permissions?.findIndex((item) => item.name == pageName);

  if (user && user?.user && user?.user?.groups && user?.user?.groups[0]?.name && user?.user?.groups[0]?.name != "super_admin") {

    if (elementIndex == -1) {
      return false;
    } else {
      if (
        user &&
        user.permissions &&
        user.permissions[elementIndex] && user.permissions[elementIndex]?.add
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  else {
    return true;
  }
};