import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, DateRange, Heading, Select } from '../../../components/Common';
import { lowStockProduct, lowStockProductDownload } from '../../../Service/Report.service';
import { useTranslation } from 'react-i18next';
import TableRowsLoader from '../../../components/Common/Loader/Skeleton';
import { notificationSuccess } from '../../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';

const LowStockProduct = () => {
  const [fromData, setFromData] = useState('')
  const [productData, setProductData] = useState([])
  const [toData, setToData] = useState('')
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTopSellingReport = async () => {
    // Create a new Date object from the date string

    let date = new Date(fromData);

    // Add one day to the date
    date.setDate(date.getDate() + 1);

    // Convert the new date to ISO string format
    let newISOString = date != "Invalid Date" ? date.toISOString() : "";
    const selectQry = `?fromDate=${newISOString}&toDate=${toData}`
    const res = await lowStockProduct(selectQry);
    if (res.status) {
      setProductData(res.data)
    }
    else {
      setProductData(null)
    }
  }

  useEffect(() => {
    setProductData([])
    getTopSellingReport()
  }, [fromData, toData])

  const downloadReport = async () => {
    let date = new Date(fromData);

    // Add one day to the date
    date.setDate(date.getDate() + 1);

    // Convert the new date to ISO string format
    let newISOString = date != "Invalid Date" ? date.toISOString() : "";

    const selectQry = `?fromDate=${newISOString}&toDate=${toData}`
    const res = await lowStockProductDownload(selectQry);
    if (res) {

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'LowStock.xlsx');
      document.body.appendChild(link);
      link.click();

      dispatch(notificationSuccess({
        message: "Report Downloaded Successfully",
        show: true,
        type: "success"
      }))

    } else {
      dispatch(notificationSuccess({
        message: "Report Downloaded Failed",
        show: true,
        type: "error"
      }))
    }
  }



  return (
    <Box>
      <Stack mb={2}>
        <Heading head='Low Stock Product' />
        <Stack flexDirection={'row'} justifyContent={'space-between'} mt={1}>
          <Stack flexDirection={'row'} gap={1}>
            <DateRange
              fromDate={fromData}
              setFromDate={setFromData}
              toDate={toData}
              setToDate={setToData}
            />
          </Stack>
          <Button buttonName='Export Report' onClick={() => {
            downloadReport()
          }} size='small' />
        </Stack>
      </Stack>
      <Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>Sr No.</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productData && productData?.map((item, index) => (
                <TableRow onClick={() => navigate("/view-product", {
                  state: {
                    id: item.productId
                  }
                })} sx={{ cursor: 'pointer' }} key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.productname}</TableCell>
                  <TableCell>{item.totalStock}</TableCell>
                </TableRow>
              ))}

              {productData === null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>

              ) : (
                productData && productData.length === 0 ? (<TableRowsLoader colNumber={3} rowsNum={10} />) : ("")
              )}

            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  )
}

export default LowStockProduct