import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { BackButton, Button, Heading, Input } from '../../components/Common'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaSlackHash } from "react-icons/fa";
import { FaStarOfLife } from "react-icons/fa";
import { customerinvoiceList, CreditNoteApply } from '../../Service/Debit.service'
import moment from 'moment';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const ApplyCreditToInvoice = () => {
  const navigate = useNavigate();
  const { state } = useLocation()
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [invoiceData, setInvoiceData] = React.useState([]);
  const [creditNumber, setCreditNumber] = React.useState('');
  const [remainingAmount, setRemainingAmount] = React.useState(0);

  useEffect(() => {
    if (state && state?.customerId) {
      getInvoiceListByCustomer(state.customerId)
      setCreditNumber(state.creditNumber)
    }
  }, [])

  const handleCreditChange = (value, data) => {
    console.log('value', value, data)
    setInvoiceData(invoiceData.map((item) => {
      if (item.id == data.id) {
        return { ...item, creditApplied: value }
      }
      return item;
    }
    ))
  }

  const getInvoiceListByCustomer = async (id) => {
    let query = `?customerId=${id}&creditNoteId=${state.creditNoteId}`;
    let res = await customerinvoiceList(query);
    if (res?.status) {
      setInvoiceData(res?.data.map((item) => {
        return { ...item, creditApplied: 0 }
      }));

      setRemainingAmount(res?.remainingAmount);
    } else {
      setInvoiceData(null);
    }
  }

  const handleSave = async () => {
    const payload = {
      customerId: state.customerId,
      creditNoteId: state.creditNoteId,
      payment: invoiceData.map((item) => {
        return {
          orderId: item.orderId,
          applyAmount: item.creditApplied
        }
      }
      )
    }
    const res = await CreditNoteApply(payload);
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: t('Credit applied successfully')
      }))
      navigate('/view-credit-note', {
        state: {
          id: state.creditNoteId
        }
      })
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res?.message
      }))
    }
  }

  return (
    <div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <BackButton />
          <Heading head={'Apply Credit To Invoice'} onClick={() => navigate('/apply-credit-to-bill')} />
        </Stack>
      </Stack>
      <Box className='card_container border_card' p={3}>
        <Stack gap={3}>
          <Stack flexDirection={'row'} gap={5} my={2}>
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <Box sx={{ width: '45px', height: '45px', background: '#f5f5f5', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}><FaSlackHash style={{ fontSize: '20px' }} /></Box>
              <Stack>
                <Typography fontWeight={'400'} fontSize={'14px'}>Credit Note#</Typography>
                <Typography fontWeight={'600'}>{creditNumber}</Typography>
              </Stack>
            </Stack>
            <Stack flexDirection={'row'} gap={'10px'} alignItems={'center'}>
              <Box sx={{ width: '45px', height: '45px', background: '#f5f5f5', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}><FaStarOfLife style={{ fontSize: '18px' }} /></Box>
              <Stack>
                <Typography fontWeight={'400'} fontSize={'14px'}>Available Credit</Typography>
                <Typography fontWeight={'600'}>{remainingAmount}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow >
                    <TableCell>Invoice Number</TableCell>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>Invoice Amount</TableCell>
                    <TableCell>Due Amount</TableCell>
                    <TableCell>Credits to Apply</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData && invoiceData.length > 0 && invoiceData?.map((data, i) => {
                    return (
                      <TableRow key={i}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                        <TableCell style={{ cursor: 'pointer' }}>{data?.invoiceNumber}</TableCell>
                        <TableCell style={{ cursor: 'pointer' }}>{moment(data?.invoiceDate).format('DD-MM-YYYY')}</TableCell>
                        <TableCell style={{ cursor: 'pointer' }}>{data?.payableAmount}</TableCell>
                        <TableCell style={{ cursor: 'pointer' }}>{data?.dueAmount}</TableCell>
                        <TableCell style={{ cursor: 'pointer' }}>
                          <Input className='credit_input' onChange={(e) => {
                            let reg = /^[0-9]*\.?[0-9]{0,2}$/;
                            if (!reg.test(e.target.value)) {
                              return false;
                            }
                            if (parseFloat(e.target.value) == parseFloat(data?.dueAmount)) {
                              return false;
                            }
                            else if (parseFloat(e.target.value) + parseFloat(invoiceData?.filter((item) => item.id != data.id).reduce((acc, item) => acc + parseFloat(item.creditApplied || 0), 0)) > parseFloat(remainingAmount)) {
                              dispatch(notificationSuccess({
                                show: true,
                                type: 'error',
                                message: t('Credit applied should not be greater than remaining amount')
                              }))
                              return false;
                            }
                            else {
                              let value = e.target.value.replace(/^0+/, '');
                              handleCreditChange(value, data)
                            }
                          }}
                            value={data?.creditApplied}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <Stack sx={{ background: '#f5f5f5', width: '400px', p: '13px 15px', gap: '5px', borderRadius: '6px' }}>
              <Stack flexDirection={'row'} gap={4} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontWeight={'400'}>Credits Applied</Typography>
                <Typography fontWeight={'400'}>{invoiceData?.reduce((acc, item) => acc + parseFloat(item.creditApplied || 0), 0)}</Typography>
              </Stack>
              <Stack flexDirection={'row'} gap={4} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontWeight={'600'}>Remaining Credits</Typography>
                <Typography fontWeight={'600'}>{remainingAmount - (invoiceData?.reduce((acc, item) => acc + parseFloat(item.creditApplied || 0), 0) || 0)}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack alignItems={'flex-end'} flexDirection={'row'} gap={1}>
            <Button buttonName='Cancel' color='white' onClick={() => navigate('/view-credit-note', {
              state: {
                id: state.creditNoteId
              }
            })} />
            <Button buttonName='Save' onClick={(e) => {
              handleSave(e)
            }}
            />
          </Stack>
        </Stack>
      </Box>
    </div >
  )
}

export default ApplyCreditToInvoice