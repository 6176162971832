import { deleteRequest, get, post, put } from "../web.request";


export const addInvoice = async (id, data) => {
  return await post(`/addSalesInvoice/${id}`, data);
}

export const invoiceList = async (query) => {
  return await get(`/salesInvoiceList${query}`);
}


export const invoiceDetail = async (id) => {
  return await get(`/salesInvoiceDetail/${id}`);
}