import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import { useTranslation } from 'react-i18next'

const AddVendor = ({ handleClose, formik, modalOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? t('Edit') : t('Create')} {t('Vendor')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: '900px' }} paddingY={1}>

          <Grid item xs={12} sm={12}>
            <Input labelinput={t('Name')}
              name='name'
              formik={formik}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={t('companyName')}
              name='companyName'
              formik={formik}
              maxLength={50}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input labelinput={t('Email')}
              name='email'
              formik={formik}
              disabled={modalOpen.isEdit}
              maxLength={50}
            />
          </Grid>

         
          <Grid item xs={12} sm={6}>
            <Input labelinput={t('mobileNumber')}
              name='mobileNumber'
              formik={formik}
              maxLength={12}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Input labelinput={t('Address_Line_1')}
              name='addressLine1'
              formik={formik}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input labelinput={t('Address_Line_2')}
              name='addressLine2'
              formik={formik}
              maxLength={100}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Input labelinput={t('Country')}
              name='country'
              formik={formik}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input labelinput={t('State')}
              name='state'
              formik={formik}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input labelinput={t('City')}
              name='city'
              formik={formik}
              maxLength={30}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input labelinput={t('ZipCode')}
              name='zipCode'
              formik={formik}
              maxLength={7}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? t('Save') : t('Create')}
          size='small' onClick={formik.handleSubmit} />
      </DialogActions>
    </>
  )
}

export default AddVendor