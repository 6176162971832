import { deleteRequest, get, post, put } from "../web.request";

export const CompanyList = async (query) => {
  return await get(`/companyList${query}`)
}

export const CompanyCreate = async (data) => {
  return await post(`/addCompany `, data);
}

export const CompanyUpdate = async (id, data) => {
  return await put(`/updateCompany/${id}`, data);
}

export const CompanyDetail = async (id) => {
  return await get(`/companyDetail/${id}`);
}

export const CompanyDelete = async (id) => {
  return await deleteRequest(`/deleteCompany/${id}`);
}

export const CompanyDropdown = async () => {
  return await get(`/companyDropdown`);
}

export const CompanyStatusChange = async (id) => {
  return await put(`/statusChangeCompany/${id}`);
}

