import { Box, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { BackButton, Button, Heading } from '../../components/Common';
import { paymentDetail } from '../../Service/payment.recieved';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import numWords from 'num-words';
import { useTranslation } from 'react-i18next';

const PaymentReceivedDetails = () => {

  const [paymentData, setPaymentData] = useState([])
  const { state } = useLocation();
  const { t } = useTranslation()

  const getPaymentData = async (id) => {
    const res = await paymentDetail(id)
    if (res) {
      setPaymentData(res.data)
    } else {
      setPaymentData([])
    }
  }

  useEffect(() => {
    if (state && state?.id) {
      getPaymentData(state?.id)
    }
  }, [state])


  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <BackButton />
          <Heading head={t('PaymentReceivedDetails')} />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          {/* <Button buttonName='Edit' startIcon={<RiEditCircleLine style={{ fontSize: '14px' }} />} /> */}
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className='card_container' p={7} style={{ maxWidth: '210mm', height: '275mm', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: 'unset' }}>

            <Stack>
              <Stack px={4} py={3}>
                <Typography fontSize={'20px'} fontWeight={'600'} sx={{ textTransform: 'uppercase' }}>{paymentData?.organisationData?.name}</Typography>
                <Stack py={2} gap={'5px'}>
                  <Typography color={'#999'} fontSize={'15px'}>{
                    paymentData?.organisationData?.address1 + ', ' +
                    paymentData?.organisationData?.address2 + ', ' +
                    paymentData?.organisationData?.city + ', ' +
                    paymentData?.organisationData?.state + ', ' +
                    paymentData?.organisationData?.country + ', ' +
                    paymentData?.organisationData?.zipcode
                  }</Typography>
                  <Typography color={'#999'} fontSize={'15px'}>{paymentData?.organisationData?.mobileNo}</Typography>
                  <Typography color={'#999'} fontSize={'15px'}>{paymentData?.organisationData?.email}</Typography>
                </Stack>
              </Stack>
              <Divider sx={{ borderColor: '#00000070' }} />
              <Stack p={2}>
                <Typography fontSize={'18px'} py={4} fontWeight={'500'} sx={{ textTransform: 'uppercase', textAlign: 'center' }}>{t("paymentreceipt")}</Typography>
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'start'} py={2}>
                  <Stack gap={'10px'}>
                    <Stack flexDirection={'row'}>
                      <Typography fontSize={'14px'} color={'#999'} width={'150px'}>{t("PaymentDate")}</Typography>
                      <Typography fontSize={'14px'} fontWeight={'500'}>: &nbsp; &nbsp; {moment(paymentData?.paymentDate).format('DD-MM-YYYY')}</Typography>
                    </Stack>

                    <Stack flexDirection={'row'}>
                      <Typography fontSize={'14px'} color={'#999'} width={'150px'}>{t("PaymentMode")}</Typography>
                      <Typography fontSize={'14px'} fontWeight={'500'}>: &nbsp; &nbsp; {paymentData?.paymentType}</Typography>
                    </Stack>
                    <Stack flexDirection={'row'}>
                      <Typography fontSize={'14px'} color={'#999'} width={'150px'}>{t("AmoutInWords")}</Typography>
                      <Typography fontSize={'14px'} fontWeight={'500'}>: &nbsp; &nbsp; {numWords(parseInt(paymentData?.totalPaidAmount))}</Typography>
                    </Stack>
                    <Stack flexDirection={'row'}>
                      <Typography fontSize={'14px'} color={'#999'} width={'150px'}>{t("Note")}</Typography>
                      <Typography fontSize={'14px'} fontWeight={'500'}>: &nbsp; &nbsp; {paymentData?.note}</Typography>
                    </Stack>
                  </Stack>
                  <Stack sx={{ background: '#78ae54', px: 3, py: 1 }}>
                    <Typography color={'#fff'} textAlign={'center'}>{t("AmountPaid")}</Typography>
                    <Typography fontWeight={'600'} fontSize={'20px'} color={'#fff'} textAlign={'center'}>&#8377; {paymentData?.totalPaidAmount}</Typography>
                  </Stack>
                </Stack>
                <Stack pt={7} pb={4}>
                  <Stack>
                    <Typography fontWeight={'600'} color={'#999'}>{t("BillTo")}</Typography>
                    <Typography fontSize={'18px'} fontWeight={'600'}>{paymentData?.customerId?.name}</Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Table stickyHeader className='purchase_order_table' aria-label="simple table" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("InvoiceNumber")} </TableCell>
                        <TableCell>{t("InvoiceDate")}</TableCell>
                        <TableCell>{t("InvoiceAmount")}</TableCell>
                        <TableCell>{t("PaymentAmout")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentData && paymentData.invoiceData && paymentData.invoiceData.length > 0 && paymentData.invoiceData.map((data, index) => (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell>{data?.invoiceNumber}</TableCell>
                          <TableCell>{moment(data?.orderId?.invoiceDate).format('DD-MM-YYYY')}</TableCell>
                          <TableCell> &#8377; {data && data?.orderId && data?.orderId?.payableAmount}</TableCell>
                          <TableCell>&#8377; {data?.paymentAmount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid >
    </>
  )
}

export default PaymentReceivedDetails