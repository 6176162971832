import React from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton, Box } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import { Button, Input, Select } from '../../components/Common';
import { statusFilter } from '../../helper/Helper';
import { useTranslation } from 'react-i18next';

const PaymentFilter = ({ openDrawer, setValue, toggleDrawer, formik, }) => {
  const drawerWidth = 250;
  const { t } = useTranslation();

  return (
    <>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2 },
        }}
      >
        <Box height={'100%'}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={'18px'}>{t('Filter')}</Typography>
              <IconButton
                aria-label="close"
                onClick={toggleDrawer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Grid>
            <Grid item xs={12} marginY={'5px'}>
              <Divider sx={{ borderColor: "gray.main", }} />
            </Grid>

            <Grid item xs={12} marginY={2} spacing={2}>
              <Grid item xs={12} mb={2}>
                <Input labelinput={t('FromDate')}
                  name='fromDate'
                  formik={formik}
                  type='date'
                  isDate={true}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <Input
                  type='date'
                  labelinput={t('ToDate')}
                  name='toDate'
                  formik={formik}
                  isDate={true}
                />
              </Grid>

            </Grid>
          </Grid>
        </Box>
        <Stack flexDirection={'row'} gap={1}>
          <Button buttonName={t('Clear')} color='white' style={{ width: '100%' }} size='small' onClick={() => {
            formik.resetForm();
            setValue([]);
            toggleDrawer();
            formik.handleSubmit();
          }} />
          <Button buttonName={t('Filter')} style={{ width: '100%' }} size='small' onClick={formik.handleSubmit} />
        </Stack>
      </Drawer>
    </>
  )
}

export default PaymentFilter