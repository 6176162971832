import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import { useTranslation } from 'react-i18next'
import Notification from '../../../components/Common/Notification/Notification'

const StatusChange = ({ handleClose, data, formik, showNotification, setShowNotification, modalOpen, isLoading }) => {
  const { t } = useTranslation()

  console.log('formik.values', showNotification)

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("OrderStatusChange")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} width={{ sm: '500px' }} paddingY={1}>
          {console.log('formik.values.status', formik.values.shipmentStatus)}


          <Grid item xs={12} sm={12}>
            {formik.values.shipmentStatus == 2 && (

              isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                <Input labelinput={t('Shipping Date')}
                  name='shipmentDate'
                  formik={formik}
                  type='date'
                  isDate={true}
                />
              )
            )}
            {formik.values.shipmentStatus == 3 && (

              isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                <Input labelinput={t('Dilivery Date')}
                  name='delivereDate'
                  formik={formik}
                  type='date'
                  isDate={true}
                />
              )
            )}
            {formik.values.shipmentStatus == 1 && (
              <Typography>{t('Are you sure you want to change the status to Packed?')}</Typography>
            )}
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
        <Button buttonName={t('Save')}
          size='small' onClick={formik.handleSubmit} disabled={isLoading} />
      </DialogActions>
      <Notification show={showNotification} setShow={setShowNotification} />
    </>
  )
}

export default StatusChange