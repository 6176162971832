import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Box, Switch, Chip } from '@mui/material'
import { Button, Heading, MenuButton, Modal } from '../../components/Common'
import { useNavigate } from 'react-router-dom'
import { Pagination, Search } from '../../components/Common/Table'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import { IoAdd, IoEyeOutline } from 'react-icons/io5'
import { AiOutlineEdit } from 'react-icons/ai'
import { HiOutlineTrash } from 'react-icons/hi'
import { useFormik } from 'formik'
import { CreditNoteList } from '../../Service/creditNote.service'
import { filterPurchaseOrderInitialValues } from '../../helper/initialValues'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import CreditNoteFilter from './CreditNoteFilter'
import { VscFilter } from 'react-icons/vsc'
import { vendorDropdownForPurchase } from '../../Service/Purchase.service'

const VendorCredit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [value, setValue] = React.useState([]);
  const [totalData, setTotalData] = useState(0)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [creditNoteData, setCreditNoteData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [vendorData, setVendorData] = useState([]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const getCreditNoteList = async () => {
    setCreditNoteData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&orderBy=${shortKey}&sortFlag=${sortBy}&orderStatus=${formik.values.status}&venderId=${formik.values.vendorId}`;
    let res = await CreditNoteList(query);
    if (res?.status) {
      setCreditNoteData(res?.data);
      setTotalData(res?.total);
    } else {
      setCreditNoteData(null);
    }
  }

  useEffect(() => {
    getVendorData();
  }, [])

  const getVendorData = async () => {
    let res = await vendorDropdownForPurchase();
    if (res?.status) {
      setVendorData(res?.data);
    }
  }



  const formik = useFormik({
    initialValues: filterPurchaseOrderInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getCreditNoteList();
    },
  });

  useEffect(() => {
    getCreditNoteList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  const orderStatusMapping = {
    0: { label: t("Draft"), color: "default" },
    2: { label: t("Closed"), color: "success" },
    1: { label: t("Open"), color: "success" }
  };

  const OrderStatusCell = ({ orderStatus }) => {
    // Get the status object from the mapping or default to "Unknown"
    const status = orderStatusMapping[orderStatus] || { label: "Unknown", color: "default" };

    return (
      <TableCell className='table-hover'>
        <Chip label={status.label} color={status.color} />
      </TableCell>
    );
  };
  return (
    <div>
      <Stack mb={1}>
        <Heading head='Vendor Credit' />
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1}>
          <Search />
          <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
            <Button startIcon={<IoAdd />} onClick={() => navigate('/create-vendor-credit')} buttonName='Credit' size='small' />
            <Button buttonName={<VscFilter />} style={{ padding: '10px 11px' }} onClick={toggleDrawer} color='white' size='small' />
            <CreditNoteFilter openDrawer={openDrawer} toggleDrawer={toggleDrawer}
              formik={formik} vendorData={vendorData}
            />
          </Stack>
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell>Date</TableCell>
              <TableCell>Credit Note</TableCell>
              <TableCell>Vendor Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditNoteData && creditNoteData.length > 0 && creditNoteData?.map((data, i) => {
              return (
                <TableRow key={i} onClick={() => navigate('/view-vendor-credit', { state: { id: data?.id, } })}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell style={{ cursor: 'pointer' }}>{moment(data?.creditDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>{data?.creditNumber}</TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>{data?.vendername}</TableCell>
                  <TableCell>
                    <OrderStatusCell orderStatus={data?.status} />
                  </TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>{data?.total}</TableCell>
                  <TableCell style={{ cursor: 'pointer' }}>{data?.remainingAmount}</TableCell>
                </TableRow>
              )
            })}

            {creditNoteData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("No_records_to_display")}
                </TableCell>
              </TableRow>

            ) : (
              creditNoteData && creditNoteData.length === 0 ? (<TableRowsLoader colNumber={6} rowsNum={10} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {
        // Conditionally render Pagination component if purchaseOrderData is not null
        creditNoteData != null && (
          <Pagination
            totalData={totalData} // Total number of data items
            page={page} // Current page number
            setPage={setPage} // Function to update current page number
            rowsPerPage={rowsPerPage} // Number of rows per page
            setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
          />
        )
      }
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })} />
    </div>
  )
}

export default VendorCredit