import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Switch } from '@mui/material'
import { Button, Heading, Modal } from '../../components/Common'
import { Pagination, Search } from '../../components/Common/Table'
import { changeUomStatus, uomCreate, uomDetail, uomList, uomUpdate } from '../../Service/Uom.service'
import moment from 'moment'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'
import { VscFilter } from 'react-icons/vsc'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import { addUomInitialValues, filterTaxInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import { addUomValidationSchema } from '../../helper/validation'
import { useTranslation } from 'react-i18next'
import UomFilter from './UomFilter'

const UomList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [uomListData, setUomListData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [maxCharacters, setMaxCharacters] = useState(20); // Default maximum characters
  const [value, setValue] = React.useState([]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const formik1 = useFormik({
    initialValues: filterTaxInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getUomTList();
    },
  });
  const formik = useFormik({
    initialValues: addUomInitialValues,
    onSubmit: async (value) => {
      setIsLoading(true)
      const payload = {
        "name": value.name,
      }
      let res = isModalOpen?.isEdit ? await uomUpdate(isModalOpen?.id, payload) : await uomCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || t('Success')
        }))
        getUomTList();
        setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', isEdit: false, isView: false });
        formik.resetForm();
        setIsLoading(false);
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || t('Something_went_wrong_Please_try_again_later')
        }))
        setIsLoading(false);
      }
    },
    validationSchema: addUomValidationSchema,
  });

  const getUomDetails = async (id, type) => {
    let res = await uomDetail(id);
    if (res?.status) {
      formik.setFieldValue('name', res.data?.name);
      // setIsModalOpen({ id: id, open: true, currentComponent: "uom", isEdit: true, });
      setIsLoading(false);
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || t('Something_went_wrong_Please_try_again_later')
      }))
      setIsLoading(false);
    }
  }

  const getUomTList = async () => {
    setUomListData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&status=${formik1.values.status}`;
    let res = await uomList(query);
    if (res?.status) {
      setUomListData(res?.data);
      setTotalData(res?.total);
    } else {
      setUomListData(null);
    }
  }

  useEffect(() => {
    getUomTList();
  }, [search, page, rowsPerPage]);

  const changeStatus = async () => {
    let res = await changeUomStatus(isModalOpen?.id);
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || t('Success')
      }))
      getUomTList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || t('Something_went_wrong_Please_try_again_later')
      }
      ))
    }
  }

  useEffect(() => {
    // Update max characters based on screen width
    const updateMaxCharacters = () => {
      if (window.innerWidth < 768) {
        setMaxCharacters(20); // Set max characters for smaller screens
      } else {
        setMaxCharacters(30); // Set max characters for larger screens
      }
    };
    // Add event listener for window resize
    window.addEventListener('resize', updateMaxCharacters);

    // Initial update
    updateMaxCharacters();

    // Cleanup
    return () => window.removeEventListener('resize', updateMaxCharacters);
  }, []);


  const truncateText = (text, maxLength) => {
    return text && text != "" ? text.length > maxLength ? text.slice(0, maxLength) + '...' : text : "-"
  };

  return (
    <div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Heading head={t('UnitofMeasurement')} />
      </Stack>
      <Stack mb={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Search search={search} setSearch={setSearch} setPage={setPage} />
        <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
          <Button buttonName={t('Create')}
            size='small' onClick={() => setIsModalOpen({ open: true, currentComponent: "uom" })} />
          <Button onClick={toggleDrawer} buttonName={<VscFilter />} style={{ padding: '10px 11px' }} color='white' size='small' />
          <UomFilter
            value={value}
            setValue={setValue}
            openDrawer={openDrawer} toggleDrawer={toggleDrawer} formik={formik1} />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell style={{ cursor: 'pointer' }} > {t("Name")} </TableCell>
              <TableCell style={{ cursor: 'pointer' }} >{t("CreatedDate")}
              </TableCell>
              <TableCell>{t("Status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uomListData && uomListData.length > 0 && uomListData?.map((data, i) => {
              return (
                <TableRow key={i} onClick={() => getUomDetails(data?.id, 1)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell className='table-hover' title={data && data?.name}>{truncateText(data?.name, maxCharacters)}</TableCell>
                  <TableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</TableCell>
                  <TableCell
                  ><Switch size="small"
                    onClick={(e) => e.stopPropagation()}
                    checked={data?.status === 1 ? true : false}
                    onChange={(e) => {
                      setIsModalOpen({ id: data?.id, open: true, currentComponent: "status", head: t("ChangeStatus"), para: t("Are_you_sure_you_want_to_Change_the_Status?") })
                    }}
                    disabled={data?.status != 2 ? false : true}
                    /></TableCell>

                </TableRow>
              )
            })}

            {uomListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("No_records_to_display")}
                </TableCell>
              </TableRow>

            ) : (
              uomListData && uomListData.length === 0 ? (<TableRowsLoader colNumber={3} rowsNum={10} />) : ("")
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {
        // Conditionally render Pagination component if uomListData is not null
        uomListData != null && (
          <Pagination
            totalData={totalData} // Total number of data items
            page={page} // Current page number
            setPage={setPage} // Function to update current page number
            rowsPerPage={rowsPerPage} // Number of rows per page
            setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
          />
        )
      }
      <Modal
        modalOpen={isModalOpen}
        formik={formik}
        isLoading={isLoading}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "" })
          formik.resetForm()
        }}
        handelClick={changeStatus}
      />
    </div >
  )
}

export default UomList