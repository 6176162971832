import { deleteRequest, get, post, put } from "../web.request";

export const productList = async (query) => {
  return await get(`/productList/${query}`);
}

export const changeProductStatus = async (id) => {
  return await put(`/statusChangeProduct/${id}`);
}

export const deleteProduct = async (id) => {
  return await deleteRequest(`/deleteProduct/${id}`);
}

export const productDetail = async (id) => {
  return await get(`/productDetail/${id}`);
}

export const addProduct = async (data) => {
  return await post(`/addProduct`, data);
}

export const updateProduct = async (id, data) => {
  return await put(`/updateProduct/${id}`, data);
}

export const productDropdown = async () => {
  return await get(`/productDropdown`);
}