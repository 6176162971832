import { Box, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BackButton, Button, Heading, Modal } from '../../components/Common';
import { useLocation } from 'react-router';
import { AdjustMentDetail, statusChangeDrafttoConfirm } from '../../Service/Adjustment.service';
import { useTranslation } from 'react-i18next';
import { RiEditCircleLine } from 'react-icons/ri';
import moment from 'moment';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';

const ViewInvAdjustment = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [adjustmentDetails, setAdjustmentDetails] = useState();
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });


  useEffect(() => {
    if (state && state?.id) {
      getAdjustmentDetails(state?.id);
    }
  }, [state]);

  const statuschange = async () => {
    let res = await statusChangeDrafttoConfirm(adjustmentDetails?.id);
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
      getAdjustmentDetails(adjustmentDetails?.id);
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || t('Something_went_wrong_Please_try_again_later')
      }))
    }
  }


  const getAdjustmentDetails = async (id) => {
    const res = await AdjustMentDetail(id);
    if (res.status) {
      setAdjustmentDetails(res.data);
    } else {
      setAdjustmentDetails(null);
    }
  }

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} gap={1} alignItems={'center'} mb={2}>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <BackButton />
          <Heading head={t('ViewInventoryAdjustment')} />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          {adjustmentDetails?.status === 0 && <Button buttonName='Convert to Adjusted' onClick={() => {
            setIsModalOpen({ id: adjustmentDetails?.id, open: true, currentComponent: "status", head: t("ChangeStatus"), para: "Are you sure you want to change status draft to confirm?" })

          }} />}


          <Button buttonName={t('Edit')}
            onClick={() => navigate('/update-inventory-adjustment', {
              state: {
                id: adjustmentDetails?.id,
                isEdit: true
              }
            })}
            startIcon={<RiEditCircleLine style={{ fontSize: '14px' }} />} />
        </Stack>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className='card_container' p={4}>
            <Stack gap={1} p={2}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'} width={'170px'}>{t("Date")}</Typography>
                <Typography fontWeight={'500'} fontSize={'18px'}>: &nbsp; &nbsp; {moment(adjustmentDetails?.date).format('DD-MM-YYYY')}</Typography>
              </Stack>
              <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'} width={'170px'}>{t("Reason")}</Typography>
                <Typography fontWeight={'500'} fontSize={'18px'}>: &nbsp; &nbsp; {adjustmentDetails?.reason}</Typography>
              </Stack>

              <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'} width={'170px'}>{t("Createdby")}</Typography>
                <Typography fontWeight={'500'} fontSize={'18px'}>: &nbsp; &nbsp; {adjustmentDetails && adjustmentDetails?.createdBy && adjustmentDetails?.createdBy.firstName + ' ' + adjustmentDetails?.createdBy.lastName}</Typography>
              </Stack>
            </Stack>
            <Stack mt={3}>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow >
                      <TableCell style={{ cursor: 'pointer' }}>{t("Product")}</TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>{t("QuantityAdjusted")}</TableCell>
                      <TableCell style={{ cursor: 'pointer' }}>{t("CostPrice")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adjustmentDetails && adjustmentDetails?.product && adjustmentDetails?.product?.map((data, i) => {
                      return (
                        <TableRow >
                          <TableCell style={{ cursor: 'pointer' }}>{data?.productId.productName}</TableCell>
                          <TableCell style={{ cursor: 'pointer' }}>{data?.adjustmentType == 1 ? "+" : "-"} {data?.adjustmentQuantity}</TableCell>
                          <TableCell style={{ cursor: 'pointer' }}>{data?.productId?.costPrice}</TableCell>
                        </TableRow>
                      )
                    })}
                    {adjustmentDetails && adjustmentDetails?.salesOrderData && adjustmentDetails?.salesOrderData && adjustmentDetails?.salesOrderData.length === 0 && (
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "silver",
                            textAlign: "center",
                            paddingTop: "90px",
                            borderBottom: "none",
                            fontSize: "30px",
                          }}
                          colSpan="6"
                        >
                          {t("No_records_to_display")}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>

          </Box>
        </Grid>

      </Grid>
      <Modal
        modalOpen={isModalOpen} // Indicates whether the modal is open or closed
        handelClick={statuschange} // Handler function to change the status of the modal
        handleClose={() => {
          setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
        } // Handler function to close the modal and reset its state
        }
      />
    </>
  )
}

export default ViewInvAdjustment