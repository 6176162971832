import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "../assets/locales/en/translation.json";
import translationRU from "../assets/locales/ru/translation.json";
import translationAR from "../assets/locales/ar/translation.json";
import translationES from "../assets/locales/es/translation.json";
import translationFR from "../assets/locales/fr/translation.json";

const fallbackLng = ["ru"];
const availableLanguages = ["en", "ru", "ar", "es", "fr"];

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  ar: {
    translation: translationAR
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  }

};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    },
    //set main language to it
    lng: localStorage.getItem("i18nextLng") ?? "ru",
  });

export default i18n;
