import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import { useTranslation } from 'react-i18next'
import { User } from '../../../helper/Constant'

const ViewAdmin = ({ handleClose, formik, data, modalOpen, files, setFiles }) => {
  const { t } = useTranslation();
  console.log('formik.values', formik.values)

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t('View')} {t('Admin')} {t('Details')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: '900px' }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <img src={User} alt='' />
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'}>{t("Name")}</Typography>
              <Typography fontWeight={'500'} fontSize={'18px'}>{formik.values.firstName} {formik.values.lastName}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'}>{t("Email")}</Typography>
              <Typography fontWeight={'500'} fontSize={'18px'}>{formik.values.email}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'}>{t("Phone")}</Typography>
              <Typography fontWeight={'500'} fontSize={'18px'}>{formik.values.mobileNumber}</Typography>
            </Stack>

          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'}>{t("Role")}</Typography>
              <Typography fontWeight={'500'} fontSize={'18px'}>{formik.values.roleName}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography fontWeight={'500'} fontSize={'18px'} color={'#999'}>{t("Address")}</Typography>
              <Typography fontWeight={'500'} fontSize={'18px'}>{
                formik.values.addressLine1 ? formik.values.addressLine1 + ', ' : ''}{formik.values.addressLine2 ? formik.values.addressLine2 + ', ' : ''}{formik.values.city ? formik.values.city + ', ' : ''}{formik.values.state ? formik.values.state + ', ' : ''}{formik.values.country ? formik.values.country + ', ' : ''}{formik.values.zipCode ? formik.values.zipCode : ''
                }</Typography>


            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Close')} size='small' color='white' onClick={handleClose} />
      </DialogActions>
    </>
  )
}

export default ViewAdmin