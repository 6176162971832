import { deleteRequest, get, post, put } from "../web.request";

export const customerDropdownForReport = async () => {
  return await get(`/customerOrderDropdown`);
}

//salesInvoiceReport
export const salesInvoiceReport = async (qry) => {
  return await get(`/salesInvoiceReport${qry}`);
}

//salesInvoiceReport Download
export const salesInvoiceReportDownload = async (qry) => {
  return await get(`/salesInvoiceReportDownload${qry}`);
}

//topSallingProduct
export const topSallingProduct = async (qry) => {
  return await get(`/topSallingProduct${qry}`);
}

//topSallingProduct Download
export const topSallingProductDownload = async (qry) => {
  return await get(`/topSallingProductDownload${qry}`);
}

// lowSallingProduct
export const lowStockProduct = async (qry) => {
  return await get(`/lowSallingProduct${qry}`);
}

// lowSallingProduct Download
export const lowStockProductDownload = async (qry) => {
  return await get(`/lowSallingProductDownload${qry}`);
}

//salesInvoiceReport
export const salesItemReport = async (qry) => {
  return await get(`/salesItemReport${qry}`);
}

//salesItemReportDownload
export const salesItemReportDownload = async (qry) => {
  return await get(`/salesItemReportDownload${qry}`);
}

//purchaseItemReport
export const purchaseItemReport = async (qry) => {
  return await get(`/purchaseItemReport${qry}`);
}

//purchaseItemReportDownload
export const purchaseItemReportDownload = async (qry) => {
  return await get(`/purchaseItemReportDownload${qry}`);
}


//customerPaymentMadeReport
export const customerPaymentMadeReport = async (qry) => {
  return await get(`/customerPaymentMadeReport${qry}`);
}

