import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import { useTranslation } from 'react-i18next'

const TaxAdd = ({ handleClose, formik, modalOpen, isLoading }) => {
  const { t } = useTranslation();


  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? t('Edit') : t('Create')} {t('Tax')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} width={{ sm: '500px' }} paddingY={1}>

          <Grid item xs={12} sm={12}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('Name')}
              name='name'
              formik={formik}
              maxLength={50}
            />)}
          </Grid>

          <Grid item xs={12} sm={6}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('Label')}
              name='label'
              formik={formik}
              maxLength={100}
            />)}
          </Grid>


          <Grid item xs={12} sm={6}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('Description')}
              name='description'
              formik={formik}
              maxLength={150}
            />)}
          </Grid>

         
          <Grid item xs={12} sm={6}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('TaxValue')}
              name='taxValue'
              formik={formik}
              maxLength={10}
            />)}
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? t('Save') : t('Create')}
          size='small' onClick={formik.handleSubmit} disabled={isLoading} />
      </DialogActions>
    </>
  )
}

export default TaxAdd