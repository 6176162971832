import { deleteRequest, get, post, put } from "../web.request";


export const vendorDropdown = async () => {
  return await get(`/vendorDropdown`);
}

export const vendorList = async (query) => {
  return await get(`/vendorList${query}`);
}

export const changeVendorStatus = async (id) => {
  return await put(`/statusChangeVendor/${id}`);
}

export const deleteVendor = async (id) => {
  return await deleteRequest(`/deleteVendor/${id}`);
}

export const vendorDetail = async (id) => {
  return await get(`/vendorDetail/${id}`);
}

export const vendorUpdate = async (id, data) => {
  return await put(`/updateVendor/${id}`, data);
}

export const vendorCreate = async (data) => {
  return await post(`/addVendor`, data);
}

export const vendorDropdownPayment = async () => {
  return await get(`/paymentPendingVenders`);
}
