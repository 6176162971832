import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Box, Switch } from '@mui/material'
import { Button, Heading, MenuButton, Modal } from '../../components/Common'
import { useNavigate } from 'react-router-dom'
import { Pagination, Search } from '../../components/Common/Table'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import { IoEyeOutline } from 'react-icons/io5'
import { HiOutlineTrash } from 'react-icons/hi'
import { changeTaxStatus, deleteTax, taxCreate, taxDetail, taxList, taxUpdate } from '../../Service/tax.service'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'
import { VscFilter } from 'react-icons/vsc'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import { addTaxInitialValues, filterTaxInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import { addTaxValidationSchema } from '../../helper/validation'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import PaymentFilter from './PaymentFilter'
import { paymentList } from '../../Service/PaymentMade.service'
import moment from 'moment'

const PaymentMadeList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [paymentListData, setPaymentListData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = React.useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('createdAt');
  const [sortBy, setSortBy] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(20); // Default maximum characters

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const formik = useFormik({
    initialValues: filterTaxInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      getPaymentTList();
    },
  });

  const getPaymentTList = async () => {
    setPaymentListData([]);
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}&fromDate=${formik.values.fromDate}&toDate=${formik.values.toDate}&status=${formik.values.status}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await paymentList(query);
    if (res?.status) {
      setPaymentListData(res?.data);
      setTotalData(res?.total);
    } else {
      setPaymentListData(null);
    }
  }

  useEffect(() => {
    getPaymentTList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);


  const handleSortClick = (name, by) => {
    switch (name) {
      case 'paymentDate':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('paymentDate')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('paymentDate')
        break;
    }
  };

  useEffect(() => {
    // Update max characters based on screen width
    const updateMaxCharacters = () => {
      if (window.innerWidth < 768) {
        setMaxCharacters(20); // Set max characters for smaller screens
      } else {
        setMaxCharacters(30); // Set max characters for larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxCharacters);

    // Initial update
    updateMaxCharacters();

    // Cleanup
    return () => window.removeEventListener('resize', updateMaxCharacters);
  }, []);


  const truncateText = (text, maxLength) => {
    return text && text != "" ? text.length > maxLength ? text.slice(0, maxLength) + '...' : text : "-"
  };

  return (
    <div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Heading head={t('Payments')} />
      </Stack>
      <Stack mb={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Search search={search} setSearch={setSearch} setPage={setPage} />
        <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
          <Button buttonName={t('Create')} size='small' onClick={() => navigate('/create-payment-made')} />
          <Button onClick={toggleDrawer} buttonName={<VscFilter />} style={{ padding: '10px 11px' }} color='white' size='small' />
          <PaymentFilter
            value={value}
            setValue={setValue}
            openDrawer={openDrawer} toggleDrawer={toggleDrawer} formik={formik} />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell style={{ cursor: 'pointer' }}>{t("VendorName")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("Bill")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("Mode")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }}>{t("Amount")}</TableCell>
              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('paymentDate', sortDate)}>
                <Stack flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
                  <div>{t("PaymentDate")}</div>
                  {sortDate ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </Stack>
              </TableCell>
              <TableCell>{t("Action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentListData && paymentListData.length > 0 && paymentListData.map((data, i) => {
              let billNo = "";

              if (data?.billNo && Array.isArray(data.billNo)) {
                billNo = data.billNo
                  .map(item => item?.billNumber)
                  .filter(billNumber => billNumber && billNumber.trim() !== "")
                  .join(", ");
              }

              return (
                <TableRow key={i} onClick={() => navigate('/view-payment-made', {
                  state: { id: data?.id }
                })
                }
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell>{data?.vendername}</TableCell>
                  <TableCell>{billNo}</TableCell>
                  <TableCell>{data?.paymentType}</TableCell>
                  <TableCell>{data?.totalPaidAmount ?? 0}</TableCell>
                  <TableCell>{moment(data?.paymentDate).format('DD-MM-YYYY')}</TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className='chatDropDown'>
                        <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content className='chatDropDownContent' align='end'>
                        <DropdownMenu.Item className='chatDropDownMenu'
                          onClick={() => navigate('/view-payment-made', {
                            state: { id: data?.id }
                          })
                          }
                        >
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><IoEyeOutline style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>{t("View")}</Box></Stack>
                        </DropdownMenu.Item>

                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              )
            })}

            {paymentListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("No_records_to_display")}
                </TableCell>
              </TableRow>

            ) : (
              paymentListData && paymentListData.length === 0 ? (<TableRowsLoader rowsNum={10} />) : ("")
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {
        // Conditionally render Pagination component if paymentListData is not null
        paymentListData != null && (
          <Pagination
            totalData={totalData} // Total number of data items
            page={page} // Current page number
            setPage={setPage} // Function to update current page number
            rowsPerPage={rowsPerPage} // Number of rows per page
            setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
          />
        )
      }

    </div >
  )
}

export default PaymentMadeList