export const loginInitialValues = {
  email: "",
  password: "",
  rememberMe: false,
}

export const addProductInitialValues = {
  productName: "",
  salePrice: "",
  costPrice: "",
  specification: "",
  description: "",
  deletedSpecificatinIds: "",
  tax: "",
  uom: "",
}

export const addCategoryInitialValues = {
  categoryName: "",
}
export const addSubCategoryInitialValues = {
  subCategoryName: "",
  categoryId: "",
}
export const filterValueInitialValues = {
  categoryName: "",
  status: "",
}

export const addCountryInitialValues = {
  countryName: "",
}

export const filterValueCountryInitialValues = {
  countryName: "",
  status: "",
}

export const addStateInitialValues = {
  stateName: "",
  countryId: "",
}

export const filterValueStateInitialValues = {
  stateName: "",
  countryId: "",
  status: "",
}

export const addCityInitialValues = {
  cityName: "",
  stateId: "",
  countryId: "",
}

export const filterValueCityInitialValues = {
  cityName: "",
  status: "",
  stateId: "",
  countryId: "",
  state: "",
  country: "",
}


export const filterValueSubCategoryInitialValues = {
  categoryId: "",
  subCategoryName: "",
  status: "",
}

export const changePasswordInitialValues = {
  newPassword: "",
  confirmpass: "",
  currentPassword: ""
}

export const filterValueProductInitialValues = {
  productName: "",
  categoryId: "",
  subCategory: "",
  status: "",
  formPrice: "",
  toPrice: "",
  formPriceSelect: "",
  toPriceSelect: "",
}

export const addAdminInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  mobileNumber: "",
  roleName: "",
}

export const filterAdminInitialValues = {
  status: "",
  role: "",
  email: "",
}

export const roleInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
}

export const filterRoleInitialValues = {
  status: "",

}

export const roleAddInitialValues = {
  roleName: "",
}

export const filterCompanyInitialValues = {
  status: "",
}

export const filterPurchaseOrderInitialValues = {
  status: "",
  vendorId: "",
  fromDate: "",
  toDate: "",
}

export const filterSalesInitialValues = {
  status: "",
  customerId: "",
  fromDate: "",
  toDate: "",
}
export const orderStatusChangeInitialValues = {
  shipmentStatus: "",
  shipmentDate: "",
  delivereDate: "",
}
export const filterInvoiceInitialValues = {
  status: "",
}



export const addCompanyInitialValues = {
  companyName: "",
  email: "",
  phoneNumber: "",
  vendorName: "",
  gstNumber: "",
  panNumber: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  zipCode: "",
}

export const editUserInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  zipCode: "",
  shippingAddressLine1: "",
  shippingAddressLine2: "",
  shippingState: "",
  shippingStateId: "",
  shippingCity: "",
  shippingCityId: "",
  shippingZipCode: "",


}

export const addWarehouseInitialValues = {
  warehouseName: "",
  email: "",
  phoneNumber: "",
  ownerName: "",
  companyName: "",
  gstNumber: "",
  panNumber: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  zipCode: "",
}

export const ledgernitialValues = {
  name: "",
}

export const purchaseOrderInitialValues = {
  vendorId: "",
  vendor: "",
  orderNumber: "",
  purchaseDate: "",
  venderIncNo: "",
  tax: "",
  taxValue: "",
  subTotal: "",
  discountAmount: "",
  adjustment: "",
  payableAmount: "",
  adjustmentType: "1",
  payment: true,
  paymentType: "",
  paymentDate: "",
  paymentAmount: "",
  orderStatus: "",
}

export const debitNoteInitialValues = {
  vendorId: "",
  vendor: "",
  creditDate: "",
  creditNo: "",
  note: "",
  discount: "",
  adjustment: "",
  total: "",
  subTotal: "",
  status: 1,
  adjustmentType: "1",
}

export const creditNoteInitialValues = {
  customerId: "",
  customer: "",
  creditDate: "",
  creditNo: "",
  note: "",
  discount: "",
  adjustment: "",
  total: "",
  subTotal: "",
  adjustmentType: "1",
  status: 1,
}
export const salesOrderInitialValues = {
  customerId: "",
  customer: "",
  orderNumber: "",
  saleDate: "",
  tax: 0,
  taxValue: "",
  subTotal: "",
  discountAmount: 0,
  adjustment: 0,
  payableAmount: "",
  adjustmentType: "1",
  total: ""

}
export const adjustmentAddInitialValues = {
  reason: "",
  date: "",

}


export const paymentMadeInitialValues = {
  paymentType: "",
  paymentMode: "",
  paymentDate: "",
  dueAmount: "",
  vendorId: "",
  vendor: "",
  note: ""
}

export const paymentRecievedInitialValues = {
  paymentType: "",
  paymentMode: "",
  paymentDate: "",
  dueAmount: "",
  vendorId: "",
  vendor: "",
  note: ""
}


export const convertToBillInitialValues = {
  billNumber: "",
  billDate: "",
  dueDate: "",
}

export const convertToInvoiceInitialValues = {
  invoiceNumber: "",
  invoiceDate: "",
  dueDate: "",
}

export const paymentPurchaseOrderInitialValues = {
  paymentType: "",
  paymentDate: "",
  dueAmount: "",
  paymentAmount: "",
}

export const paymentSalesInitialValues = {
  paymentType: "",
  paymentDate: "",
  dueAmount: "",
  paymentAmount: "",
}

export const profileInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  profileImage: "",
  phone: "",
  address1: "",
  address2: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",

}

export const organizationInitialValues = {
  name: "",
  vatnumber: "",
  email: "",
  profileImage: "",
  phone: "",
  address1: "",
  address2: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",

}


export const filterCartInitialValues = {
  status: "",
}

export const addCartInitialValues = {
  userId: "",
  userStaeName: "",
  adminStateName: "",
  cgst: "",
  sgst: "",
  igst: "",
  cgstp: "",
  sgstp: "",
  igstp: "",
  taxValue: "",
  subTotal: "",
  discountAmount: "",
  adjustment: "",
  payableAmount: "",
  adjustmentType: "1",
}

export const addVendorInitialValues = {
  name: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  mobileNumber: "",
  companyName: "",
  sAddressLine1: "",
  sAddressLine2: "",
  sameAsBilling: false,
  sCountry: "",
  sState: "",
  sCity: "",
  sZipCode: "",
}
export const addCustomerInitialValues = {
  name: "",
  companyName: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  shippingAddressId: "",
  billingAddressId: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  mobileNumber: "",
  sAddressLine1: "",
  sAddressLine2: "",
  sameAsBilling: false,
  sCountry: "",
  sState: "",
  sCity: "",
  sZipCode: "",
}
export const filterVendorInitialValues = {
  status: "",
}

export const filterCustomerInitialValues = {
  status: "",
}

export const filterCreditNoteInitialValues = {
  status: "",
}

export const addTaxInitialValues = {
  name: "",
  label: "",
  description: "",
  taxValue: "",
}
export const addUomInitialValues = {
  name: "",
}

export const filterTaxInitialValues = {
  status: "",
  fromDate: "",
  toDate: "",
}
