import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete, BackButton, Button, Heading, Input, Modal, Select } from '../../components/Common'
import { useNavigate } from 'react-router-dom';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { AdjustMentCreate, AdjustMentUpdate, AdjustMentDetail } from '../../Service/Adjustment.service';
import { adjustmentAddInitialValues } from '../../helper/initialValues';
import { adjustmentValidationSchema } from '../../helper/validation';
import { useLocation } from 'react-router-dom';
import { AdjustMentQtyFind } from '../../Service/Adjustment.service';
import { MdAdd } from 'react-icons/md';
import { HiOutlineTrash } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { t } from 'i18next';

const AddInvAdjustment = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [deletedProductIds, setDeletedProductIds] = useState([]);
  const [change, setChange] = useState(false);
  const [files, setFiles] = useState([]);
  const [isEdit, setIsEdit] = useState({
    isEdit: false,
    id: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    class: ''
  });
  const [taxValues, setTaxValues] = useState([{ name: '', taxValue: 0, index: 0 }]);
  const [items, setItems] = useState([{ ids: uuidv4(), product: '', adjustmentType: 1, adjustQuantity: 0, newQuantity: 0, availableQuantity: 0 }]);

  const addItem = () => {
    const newItem = {
      ids: uuidv4(),
      product: '',
      adjustmentType: 1,
      adjustQuantity: 0,
      newQuantity: 0,
      availableQuantity: 0,
    };
    setItems([...items, newItem]);
  };

  const removeItem = (itemId) => {
    setItems(items.filter(item => item.ids !== itemId));
    setTaxValues(
      taxValues
        .filter((item) => item.name != "")
        .reduce((acc, item) => {
          // Subtract the tax value from existing items in the accumulator array
          const existingItem = acc.find((accItem) => accItem.name == item.name);
          if (existingItem) {
            existingItem.taxValue -= item.taxValue;
          } else {
            acc.push(item);
          }
          return acc;
        }, [])
        .filter((item) => item.taxValue != 0) // Remove items with tax value 0
    );
    if (items.find(item => item.ids === itemId).id) {
      setDeletedProductIds([...deletedProductIds, items.find(item => item.ids === itemId).id]);
    }
  };

  const handleNumericChange = useCallback((index, field, value) => {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
      handleInputChange(index, field, parseFloat(value));
      setChange(!change);
    } else {
      handleInputChange(index, field, 0);
    }
  });

  const handleInputChange = useCallback((index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  });

  const formik = useFormik({
    initialValues: adjustmentAddInitialValues,
    onSubmit: async (value) => {
      if (!validateItems(items)) {
        return;
      }

      const payload = {
        date: value.date,
        reason: value.reason,
        status: value.status,
        product: items && items.map((item) => {
          return {
            productId: item.product.id,
            id: item.id,
            adjustmentQuantity: item.adjustQuantity,
            adjustmentType: item.adjustmentType
          }
        }),
        deletedProductIds: deletedProductIds
      }

      let res = isEdit?.isEdit ? await AdjustMentUpdate(isEdit?.id, payload) : await AdjustMentCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || t('Success')
        }))
        formik.resetForm();
        Navigate('/inventory-adjustment')
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || t('Something_went_wrong_Please_try_again_later')
        }
        ))
      }
    },
    validationSchema: adjustmentValidationSchema,
  });

  useEffect(() => {
    setIsLoading(true);
    if (state && state?.isEdit && state?.id) {
      setIsEdit({ isEdit: true, id: state.id });
      getAdjustmentDetails(state.id);
    } else {
      setIsEdit({ isEdit: false, id: '' });
      setIsLoading(false);
    }
  }, [state]);


  const validateItems = (items) => {

    if (items && items == "undefined" && !items.length && items.length == 0) {
      return false; // Validation fails if there are no items
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const trimmedProduct = item.product && item.product.name || '';
      const trimmedQuantity = item.adjustQuantity || 0;

      if (trimmedProduct === '' && trimmedQuantity === 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Please_fill_in_all_the_fields')
        }));
        return false; // Validation fails if key is empty
      } else if (trimmedProduct == '') {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Product is required'
        }));
        return false; // Validation fails if key is empty
      }
      else if (trimmedQuantity == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Adjust Quantity is required'
        }));
        return false; // Validation fails if key is empty
      }

    }
    return true; // Validation passes if all items have non-empty key and value
  };

  console.log("items", items);
  const getAdjustmentDetails = async (id) => {
    const res = await AdjustMentDetail(id);
    if (res?.status) {
      formik.setValues({
        reason: res?.data?.reason,
        date: moment(res?.data?.date).format('YYYY-MM-DD'),

      });
      setItems(res?.data?.product.map((item) => {
        return {
          ids: uuidv4(),
          product: {
            id: item.productId.id,
            name: item.productId?.productName
          },
          id: item.id,
          availableQuantity: item.productId?.quantity,
          adjustQuantity: item.adjustmentQuantity,
          adjustmentType: parseInt(item.adjustmentType),
          newQuantity: item.adjustmentType == 1 ? parseInt(item.productId?.quantity) + parseInt(item.adjustmentQuantity) : parseInt(item.productId?.quantity) - parseInt(item.adjustmentQuantity)
        }
      }
      ));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      formik.resetForm();
    }
  }

  const getproductData = async () => {
    const res = await AdjustMentQtyFind();
    if (res?.status) {
      let data = res.data.map((item) => {
        return {
          id: item.id,
          name: item.productName,
          quantity: item.quantity,
        }
      }
      )
      console.log("dsdsdsdsdsd", data);
      setProductData(data);
    } else {
      setProductData([]);
    }
  }

  useEffect(() => {
    getproductData();
  }, []);

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <BackButton />
        <Heading head={`${isEdit?.isEdit ? t('Edit') : t('Create')} ${t("InventoryAdjustment")}`} />
      </Stack>
      <Box className='card_container' p={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack className='create_purchase_vendor border_card' gap={1} p={2}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                  <Input labelinput={t('AdjustmentDate')}
                    name='date'
                    variant='standard'
                    formik={formik}
                    type='date'
                    isDate={true}
                  />
                )}
              </Stack>

              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                  <Input labelinput={t('Reason')}
                    name='reason'
                    variant='standard'
                    maxLength={100}
                    formik={formik}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} mb={1} alignItems={'flex-end'}>
              <Heading smallHead={t('ProducDetails')} />

            </Stack>
            <Box className='border_card' p={2}>
              {items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Stack flexDirection={'row'} gap={1} alignItems={'flex-end'}>
                    <Box width={'100%'}>
                      <Autocomplete
                        data={productData}
                        labelinput={index == 0 ? t('Product') : ''}
                        name='product'
                        onChange={(event, newValue) => {
                          handleInputChange(index, 'product', newValue)
                          handleInputChange(index, 'availableQuantity', newValue?.quantity)
                          handleInputChange(index, 'newQuantity', newValue?.quantity)
                        }
                        }
                        value={item.product}
                      />
                    </Box>
                    <Box width={'100%'}>
                      <Input
                        placeholder={t('AvailableQuantity')}
                        labelinput={index == 0 ? t('AvailableQuantity') : ''}
                        value={item.availableQuantity}
                        disabled={true}

                      // onChange={(e) => handleNumericChange(index, 'quantity', e.target.value)}
                      />
                    </Box>
                    <Box width={'100%'}>
                      <Input
                        placeholder=''
                        labelinput={index == 0 ? t('NewQuantityonHand') : ''}
                        value={item.newQuantity || 0}
                        disabled={true}
                      />
                    </Box>
                    <Box width={'100%'}>
                      <Typography fontSize={{ xs: '10px', sm: '13px' }} fontWeight={500}>{index == 0 ? t('QuantityAdjusted') : ''}</Typography>
                      <div className='inv_add' style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <Select selectList={[{ name: '+', id: 1 }, { name: '-', id: 2 }]} onChange={(e) => {
                          handleNumericChange(index, 'adjustmentType', e.target.value)
                          handleNumericChange(index, 'newQuantity', parseInt(item.availableQuantity) + (e.target.value == 1 ? parseInt(item.adjustQuantity) : -parseInt(item.adjustQuantity)))
                        }}
                          value={item.adjustmentType}
                          name='adjustmentType'
                          formik={formik}
                        />
                        <Input
                          placeholder={t('AdjustQuantity')}
                          labelinput={index == 0 ? '' : ''}
                          value={parseFloat(item.adjustQuantity)}
                          onChange={(e) => {
                            handleNumericChange(index, 'adjustQuantity', e.target.value)
                            handleNumericChange(index, 'newQuantity', parseInt(item.availableQuantity) + (item.adjustmentType == 1 ? parseInt(e.target.value) : -parseInt(e.target.value)))
                          }}
                        />
                      </div>
                    </Box>
                    {items.length - 1 === index ? (
                      <>
                        <Button style={{ padding: '10px 11px' }} buttonName={<MdAdd style={{ fontSize: '19px' }} />} onClick={addItem} />
                        {items.length != 1 && <Button style={{ padding: '10px 11px' }} buttonName={<HiOutlineTrash style={{ fontSize: '19px' }} />} onClick={() => removeItem(item.ids)} />}
                      </>
                    )
                      : (<>
                        {/* <Button style={{ padding: '10px 11px' }} buttonName={<MdAdd style={{ fontSize: '19px' }} />} onClick={addItem} /> */}
                        < Button style={{ padding: '10px 11px' }} buttonName={<HiOutlineTrash style={{ fontSize: '19px' }} />} onClick={() => removeItem(item.ids)}
                        />
                      </>)
                    }

                  </Stack>
                  {items.length - 1 === index ? '' : <Divider sx={{ marginY: 2 }} />}
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Stack flexDirection={'row'} gap={1} mt={3} justifyContent={'flex-end'}>
          {!isEdit?.isEdit && <Button buttonName={t(`SaveasDraft`)}
            onClick={() => {
              formik.setFieldValue('status', 0);
              formik.handleSubmit();
            }}
            color='primary' />}
          <Button
            onClick={formik.handleSubmit}
            buttonName={t('ConverttoAdjusted')} />

          <Button buttonName={t(`Cancel`)}
            onClick={() => {
              Navigate('/inventory-adjustment');
            }}
            color='white' />
        </Stack>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        formik={formik}
        setFiles={setFiles}
        files={files}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "", class: '' })
          formik.resetForm()
        }} />
    </>
  )
}

export default AddInvAdjustment