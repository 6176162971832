import { Box, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Autocomplete, BackButton, Button, Heading, Input, Modal, Select } from '../../components/Common'
import { paymentPandingCustomer } from '../../Service/payment.recieved';
import { paymentRecievedInitialValues } from '../../helper/initialValues';
import { useFormik } from 'formik';
import { paymentRecienedValidationSchema } from '../../helper/validation';
import { paymentOrderData, addPaymentMade } from '../../Service/payment.recieved';
import moment from 'moment';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AddPaymentReceived = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [orderPaymentData, setOrderPaymentData] = useState([]);
  const [customerData, setCustomerData] = useState([]);


  const getCustomerData = async () => {
    const response = await paymentPandingCustomer();
    if (response && response.data) {
      setCustomerData(response.data);
    } else {
      setCustomerData([]);
    }
  }

  useEffect(() => {
    getCustomerData();
  }, []);

  const getPaymentData = async (id) => {
    let query = `?customerId=${id}`
    const response = await paymentOrderData(query);
    if (response && response.data) {
      formik.setFieldValue('dueAmount', response && response?.amount);
      setOrderPaymentData(response.data);
    } else {
      setOrderPaymentData([]);
    }
  }


  const formik = useFormik({
    initialValues: paymentRecievedInitialValues,
    onSubmit: async (value) => {

      const payload = {
        "paymentType": value.paymentMode,
        "paymentDate": value.paymentDate,
        "totalPaidAmount": parseFloat(orderPaymentData && orderPaymentData.reduce((acc, curr) => acc + parseFloat(curr.payment || 0), 0).toFixed(2)),
        "note": value.note,
        "customerId": value.customerId,
        "Payments": orderPaymentData && orderPaymentData.map((data) => {
          return {
            "orderId": data.orderId,
            "paymentAmount": data && parseFloat(data.payment) || 0,
            "invoiceNumber": data.invoiceNumber,
          }
        }
        )
      }

      let res = await addPaymentMade(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || t('Success')
        }))
        formik.resetForm();
        Navigate('/payment-received')
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || t('Something_went_wrong_Please_try_again_later')
        }
        ))
      }
    },
    validationSchema: paymentRecienedValidationSchema,
  });

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <BackButton />
        <Heading head={t("PaymentReceived")} />
      </Stack>
      <Box className='card_container' p={3}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Autocomplete
              data={customerData}
              labelinput={t('Customer')}
              onChange={(e, val) => {
                if (val) {
                  getPaymentData(val.id);
                  formik.setFieldValue('customerId', val.id);
                  formik.setFieldValue('customer', val);
                } else {
                  formik.setFieldValue('customerId', "");
                  formik.setFieldValue('customer', '');
                }
              }}
              name='customerId'
              name1='customerId'
              formik={formik}
              value={formik.values.customer}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              labelinput={t('PaymetDue')}
              name='dueAmount'
              disabled
              formik={formik}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type='date'
              labelinput={t('PaymentDate')}
              name='paymentDate'
              formik={formik}
              isDate={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              name={t('paymentMode')}
              formik={formik}
              onChange={(e) => {
                formik.setFieldValue('paymentMode', e.target.value);
              }}
              value={formik.values.paymentMode}
              selectList={[{
                id: 'Cash',
                name: t('Cash')
              }, {
                id: 'Cheque',
                name: t('Cheque')
              },
              {
                id: 'Card',
                name: t('Card')
              }, {
                id: 'UPI',
                name: t('UPI')
              }]}
              labelinput={t('PaymentMode')}
            />
          </Grid>
          <Grid item xs={12}>
            <Table stickyHeader className='purchase_order_table' aria-label="simple table" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Date")}</TableCell>
                  <TableCell>{t("DueDate")}</TableCell>
                  <TableCell>{t("InvoiceNumber")}</TableCell>
                  <TableCell>{t("InvoiceAmount")}</TableCell>
                  <TableCell>{t("AmoutDue")}</TableCell>
                  <TableCell>{t("Payment")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderPaymentData && orderPaymentData.length > 0 && orderPaymentData.map((data, index) => (
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{moment(data.createdAt).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>{data?.billNumber}</TableCell>
                    <TableCell>{data?.orderNumber}</TableCell>
                    <TableCell>&#8377; {data?.payableAmount}</TableCell>
                    <TableCell>&#8377; {data?.dueAmount}</TableCell>
                    <TableCell>
                      <TextField className='payment_made_input' value={(orderPaymentData && orderPaymentData[index].payment) ?? 0}
                        onChange={(e) => {
                          //character not allowed
                          if (e.target.value.match(/^[0-9]*$/) && parseFloat(data.dueAmount) >= parseFloat(e.target.value)) {
                            // if (e.target.value <= 0) {
                            let value = e.target.value;
                            let tempData = orderPaymentData || [];
                            tempData[index].payment = value.replace(/^0+/, '');
                            setOrderPaymentData([...tempData]);
                          }
                          else if (parseFloat(data.dueAmount) < parseFloat(e.target.value) && e.target.value !== "") {

                            dispatch(notificationSuccess({
                              show: true,
                              type: 'error',
                              message: 'Payment_amount_should_be_less_than_due_amount'
                            }))

                          }
                          else {
                            let value = e.target.value;
                            let tempData = orderPaymentData || [];
                            tempData[index].payment = 0;
                            setOrderPaymentData([...tempData]);
                          }
                        }
                        }
                      />

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems={'flex-end'}>
              <Stack flexDirection={'row'} p={1} sx={{ background: 'rgb(255, 193, 7, 0.2)' }} alignItems={'center'} justifyContent={'space-between'} gap={5} width={'340px'}>
                <Typography fontSize={'18px'} fontWeight={'500'} color={'#999'}>{t("TotalAmountPaid")} :</Typography>
                <Typography fontSize={'18px'} fontWeight={'600'}>&#8377; {orderPaymentData && orderPaymentData.length > 0 && orderPaymentData.reduce((acc, curr) => acc + parseFloat(curr.payment || 0), 0).toFixed(2) || 0}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput={t('Notes')}
              multiline
              rows={2}
              name='note'
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={'row'} gap={1} justifyContent={'flex-start'}>
              <Button buttonName={t('Discard')} color='white' />
              <Button buttonName={t('Create')} color='primary' onClick={formik.handleSubmit} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "" })
        }} />
    </>
  )
}

export default AddPaymentReceived