import { deleteRequest, get, post, put } from "../web.request";

export const CreditNoteList = async (query) => {
  return await get(`/vendorCreditList${query}`);
}

export const CreditNoteAdd = async (data) => {
  return await post(`/addVendorCredit`, data);
}

export const CreditNoteUpdate = async (data) => {
  return await put(`/creditNoteUpdate`, data);
}

export const CreditNoteDetails = async (id) => {
  return await get(`/vendorCreditDetail/${id}`);
}

//total  bill list 
export const vendorBillList = async (query) => {
  return await get(`/vendorBillList${query}`);
}


//vendor credit apply
export const vendorCreditApply = async (data) => {
  return await post(`/vendorCreditApply`, data);
}


//vendorCreditStatusChange
export const vendorCreditStatusChange = async (id) => {
  return await put(`/vendorCreditStatusChange/${id}`);
}