import { deleteRequest, get, post, put } from "../web.request";

export const salesOrderListData = async (query) => {
  return await get(`/salesOrderList${query}`);
}

export const salesOrderCreate = async (data) => {
  return await post(`/addSalesOrder`, data);
}

export const salesOrderUpdate = async (id, data) => {
  return await put(`/updateSalesOrder/${id}`, data);
}

export const salesOrderDetail = async (id) => {
  return await get(`/salesOrderDetail/${id}`);
}

export const orderStatusChange = async (data, id) => {
  return await put(`/shipmentStatusChange/${id}`, data);
}

export const salesOrderPayment = async (data) => {
  return await post(`/addPayment`, data);
}

export const statusChangeDrafttoConfirm = async (id) => {
  return await put(`/statusChangeSalesOrder/${id}`);
}