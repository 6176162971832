import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, DateRange, Heading, Select } from '../../../components/Common';
import { purchaseItemReport, purchaseItemReportDownload } from '../../../Service/Report.service';
import { useTranslation } from 'react-i18next';
import { notificationSuccess } from '../../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const PurchasesByItem = () => {
  const navigate = useNavigate();
  const [fromData, setFromData] = useState('')
  const [toData, setToData] = useState('')
  const [reportData, setReportData] = useState([])
  const [quantity, setQuantity] = useState({
    overallQuantity: 0,
    overallAmount: 0
  })

  const { t } = useTranslation();
  const dispatch = useDispatch();


  const getSalesInvoiceReport = async () => {
    const selectQry = `?fromDate=${fromData != "" ? moment(fromData).format('YYYY-MM-DD') : ""}&toDate=${toData != "" ? moment(toData).format('YYYY-MM-DD') : ""}`
    const res = await purchaseItemReport(selectQry);
    if (res.status) {
      setReportData(res.data)
      setQuantity({
        overallQuantity: res.overallQuantity,
        overallAmount: res.overallAmount
      })
    }
    else {
      setReportData([])
    }
  }

  useEffect(() => {
    getSalesInvoiceReport()
  }, [fromData, toData])

  const downloadReport = async () => {
    const selectQry = `?fromDate=${fromData != "" ? moment(fromData).format('YYYY-MM-DD') : ""}&toDate=${toData != "" ? moment(toData).format('YYYY-MM-DD') : ""}`
    const res = await purchaseItemReportDownload(selectQry);
    if (res) {

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PurchasesByItemReport.xlsx');
      document.body.appendChild(link);
      link.click();

      dispatch(notificationSuccess({
        message: "Report Downloaded Successfully",
        show: true,
        type: "success"
      }))

    } else {
      dispatch(notificationSuccess({
        message: "Report Downloaded Failed",
        show: true,
        type: "error"
      }))
    }
  }

  console.log('reportData', reportData)

  return (
    <Box>
      <Stack mb={2}>
        <Heading head='Sales by Item' />
        <Stack flexDirection={'row'} justifyContent={'space-between'} mt={1}>
          <Stack flexDirection={'row'} gap={1}>
            <DateRange
              fromDate={fromData}
              setFromDate={setFromData}
              toDate={toData}
              setToDate={setToData}
            />
          </Stack>
          <Button buttonName='Export Report' size='small' onClick={() => {
            downloadReport()
          }} />
        </Stack>
      </Stack>
      <Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Quantity Sold</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Average Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData && reportData.map((item, index) => {
                console.log('item', item)
                return (
                  <TableRow onClick={() => navigate('/view-invoice', {
                    state: {
                      id: item?.orderId,
                    }
                  })}>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.Quantity}</TableCell>
                    <TableCell>{item.Amount}</TableCell>
                    <TableCell>{item.Amount / item.Quantity}</TableCell>

                  </TableRow>
                )
              })}
            </TableBody>
            {
              reportData.length === 0 ?
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4} align="center">No Data Found</TableCell>
                  </TableRow>
                </TableBody>
                : <TableHead>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>{quantity.overallQuantity}</TableCell>
                    <TableCell>{quantity.overallAmount}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
            }
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  )
}

export default PurchasesByItem