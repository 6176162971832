import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Notification from "./components/Common/Notification/Notification";
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from "./helper/ScrollToTop";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import Loader from "./components/Common/Loader/Loader";
import { useLocation } from 'react-router-dom';
import { loadUser } from "./Service/auth.service";
import PrivateRoute from "./Routes/PrivateRoute";
import { loginUserApi, logoutApi } from "./state/Action/authAction";
import { Modal } from '../src/components/Common';
import WarehouseDetails from "./pages/Warehouse/WarehouseDetails";
import CompanyDetails from "./pages/Company/CompanyDetails";
import SalesOrderAdd from "./pages/SalesOrder/SalesOrderAdd.js";
import PurchaseBill from "./pages/PurchaseOrder/PurchaseBill.js";
import SalesInvoice from "./pages/SalesOrder/SalesInvoice.js";
import PaymentMadeList from "./pages/PaymentMade/PaymentMadeList.js";
import AddPaymentMade from "./pages/PaymentMade/AddPaymentMade.js";
import PaymentMadeDetails from "./pages/PaymentMade/PaymentMadeDetails.js";
import PaymentReceivedList from "./pages/PaymentRecieved/PaymentReceivedList.js";
import AddPaymentReceived from "./pages/PaymentRecieved/AddPaymentReceived.js";
import PaymentReceivedDetails from "./pages/PaymentRecieved/PaymentReceivedDetails.js";
import UomList from "./pages/Uom/UomList.js";
import Addvendor from "./pages/Vendor/Addvendor.js";
import AddCustomer from "./pages/Customer/AddCustomer.js";
import Organization from "./pages/Organization/index.js";
import SalesByCustomer from "./pages/Report/Pages/SalesByCustomer.js";
import InventoryAdjustment from "./pages/InventoryAdjustment/InventoryAdjustment.js";
import AddInvAdjustment from "./pages/InventoryAdjustment/AddInvAdjustment.js";
import ViewInvAdjustment from "./pages/InventoryAdjustment/ViewInvAdjustment.js";
import VendorCredit from "./pages/VendorCredit/VendorCredit.js";
import CreditNote from "./pages/CreditNote/CreditNote.js";
import AddVendorCredit from "./pages/VendorCredit/AddVendorCredit.js";
import LowStockProduct from "./pages/Report/Pages/LowStockProduct.js";
import TopSellingProduct from "./pages/Report/Pages/TopSellingProduct.js";
import PurchasesByItem from "./pages/Report/Pages/PurchasesByItem.js";
import SalesByItem from "./pages/Report/Pages/SalesByItem.js";
import PaymentReceived from "./pages/Report/Pages/PaymentReceived.js";
import PaymentMade from "./pages/Report/Pages/PaymentMade.js";
import RefundHistory from "./pages/Report/Pages/RefundHistory.js";
import ViewVendorCredit from "./pages/VendorCredit/ViewVendorCredit.js";
import ApplyCreditToBill from "./pages/VendorCredit/ApplyCreditToBill.js";
import ApplyCreditToInvoice from "./pages/CreditNote/ApplyCreditToInvoice.js";
import ViewCreditNote from "./pages/CreditNote/ViewCreditNote.js";

const Login = React.lazy(() => import('./pages/Login/Login'));
const Layout = React.lazy(() => import("./components/layouts/Layout"));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard.js'));
const Product = React.lazy(() => import("./pages/Product/Product"));
const AddProduct = React.lazy(() => import("./pages/Product/AddProduct"));
const ProductDetails = React.lazy(() => import("./pages/Product/ProductDetails"));
const ProfileSettings = React.lazy(() => import("./pages/ProfileSettings/ProfileSettings"));
const UserManagement = React.lazy(() => import("./pages/AdminManagement/AdminManagement"));
const CreateRole = React.lazy(() => import("./pages/Role/CreateRole.js"));
const VendorList = React.lazy(() => import("./pages/Vendor/Vendor.js"));
const CustomerList = React.lazy(() => import("./pages/Customer/Customer.js"));
const CustomerDetail = React.lazy(() => import("./pages/Customer/CustomerDetails.js"));
const VendorDetail = React.lazy(() => import("./pages/Vendor/VendorDetails.js"));
const WarehouseList = React.lazy(() => import("./pages/Warehouse/Warehouse"));
const WarehouseAdd = React.lazy(() => import("./pages/Warehouse/WarehouseAdd"));
const Company = React.lazy(() => import("./pages/Company/Company"));
const CompanyAdd = React.lazy(() => import("./pages/Company/CompayAdd"));
const PurchaseOrder = React.lazy(() => import("./pages/PurchaseOrder/PurchaseOrderList"));
const Payment = React.lazy(() => import("./pages/Payment/PaymentList"));
const Inventory = React.lazy(() => import("./pages/Inventory/inventoryList"));
const POrderDetails = React.lazy(() => import("./pages/PurchaseOrder/POrderDetails.js"));
const POrderAdd = React.lazy(() => import("./pages/PurchaseOrder/POrderAdd.js"));
const SalesOrder = React.lazy(() => import("./pages/SalesOrder/SalesOrderList"));
const SOrderDetails = React.lazy(() => import("./pages/SalesOrder/SOrderDetails"));
const Role = React.lazy(() => import("./pages/Role/Roles.js"));
const Tax = React.lazy(() => import("./pages/Tax/TaxList.js"));
const Report = React.lazy(() => import("./pages/Report"));
const Profit = React.lazy(() => import("./pages/Profit"));
const BillDetails = React.lazy(() => import("./pages/PurchaseOrder/BillDetails"));
const InvoiceDetail = React.lazy(() => import("./pages/SalesOrder/InvoiceDetail.js"));
const AddCreditNote = React.lazy(() => import("./pages/CreditNote/AddCreditNote.js"));

const pages = [
  { name: 'dashboard', path: 'dashboard', element: <Dashboard /> },
  { name: 'admin', path: 'admins', element: <UserManagement /> },
  { name: 'role', path: 'role', element: <Role /> },
  { name: 'role', path: 'create-role', element: <CreateRole /> },
  { name: 'role', path: 'update-role', element: <CreateRole /> },
  { name: 'vendors', path: 'vendor', element: <VendorList /> },
  { name: 'vendors', path: 'view-vendor', element: <VendorDetail /> },
  { name: 'vendors', path: 'add-vendor', element: <Addvendor /> },
  { name: 'vendors', path: 'update-vendor', element: <Addvendor /> },
  { name: 'customers', path: 'customer', element: <CustomerList /> },
  { name: 'customers', path: 'view-customer', element: <CustomerDetail /> },
  { name: 'customers', path: 'add-customer', element: <AddCustomer /> },
  { name: 'customers', path: 'update-customer', element: <AddCustomer /> },

  { name: 'product', path: 'product', element: <Product /> },
  { name: 'product', path: 'create-product', element: <AddProduct /> },
  { name: 'product', path: 'update-product', element: <AddProduct /> },
  { name: 'product', path: 'view-product', element: <ProductDetails /> },
  { name: 'product', path: 'profile-settings', element: <ProfileSettings /> },
  { name: 'warehouse', path: 'warehouse', element: <WarehouseList /> },
  { name: 'warehouse', path: 'create-warehouse', element: <WarehouseAdd /> },
  { name: 'warehouse', path: 'update-warehouse', element: <WarehouseAdd /> },
  { name: 'warehouse', path: 'view-warehouse', element: <WarehouseDetails /> },
  { name: 'company', path: 'company', element: <Company /> },
  { name: 'company', path: 'create-company', element: <CompanyAdd /> },
  { name: 'company', path: 'view-company', element: <CompanyDetails /> },
  { name: 'company', path: 'update-company', element: <CompanyAdd /> },
  { name: 'purchase-bill', path: 'purchase-bill', element: <PurchaseBill /> },
  { name: 'purchase-order', path: 'purchase-order', element: <PurchaseOrder /> },
  { name: 'purchase-order', path: 'view-purchase-order', element: <POrderDetails /> },
  { name: 'purchase-order', path: 'create-purchase-order', element: <POrderAdd /> },
  { name: 'purchase-order', path: 'update-purchase-order', element: <POrderAdd /> },
  { name: 'purchase-bill', path: 'view-bill', element: <BillDetails /> },

  { name: 'vendor-credit', path: 'vendor-credit', element: <VendorCredit /> },
  { name: 'create-vendor-credit', path: 'create-vendor-credit', element: <AddVendorCredit /> },
  { name: 'view-vendor-credit', path: 'view-vendor-credit', element: <ViewVendorCredit /> },
  { name: 'apply-credit-to-bill', path: 'apply-credit-to-bill', element: <ApplyCreditToBill /> },

  { name: 'credit-note', path: 'credit-note', element: <CreditNote /> },
  { name: 'create-credit-note', path: 'create-credit-note', element: <AddCreditNote /> },
  { name: 'view-credit-note', path: 'view-credit-note', element: <ViewCreditNote /> },
  { name: 'apply-credit-note-invoice', path: 'apply-credit-to-invoice', element: <ApplyCreditToInvoice /> },

  { name: 'payment-made', path: 'payment-made', element: <PaymentMadeList /> },
  { name: 'payment-made', path: 'create-payment-made', element: <AddPaymentMade /> },
  { name: 'payment-made', path: 'view-payment-made', element: <PaymentMadeDetails /> },

  { name: 'payment-received', path: 'payment-received', element: <PaymentReceivedList /> },
  { name: 'payment-received', path: 'create-payment-received', element: <AddPaymentReceived /> },
  { name: 'payment-received', path: 'view-payment-received', element: <PaymentReceivedDetails /> },

  { name: 'payment', path: 'payment', element: <Payment /> },
  { name: 'inventory', path: 'inventory', element: <Inventory /> },

  { name: 'inventory-adjustment', path: 'inventory-adjustment', element: <InventoryAdjustment /> },
  { name: 'add-inventory-adjustment', path: 'add-inventory-adjustment', element: <AddInvAdjustment /> },
  { name: 'add-inventory-adjustment', path: 'update-inventory-adjustment', element: <AddInvAdjustment /> },

  { name: 'view-inventory-adjustment', path: 'view-inventory-adjustment', element: <ViewInvAdjustment /> },

  { name: 'sales-invoice', path: 'sales-invoice', element: <SalesInvoice /> },
  { name: 'sales-invoice', path: 'view-invoice', element: <InvoiceDetail /> },

  { name: 'sales-order', path: 'sales-order', element: <SalesOrder /> },
  { name: 'sales-order', path: 'create-sales-order', element: <SalesOrderAdd /> },
  { name: 'sales-order', path: 'update-sales-order', element: <SalesOrderAdd /> },

  { name: 'sales-order', path: 'view-sales-order', element: <SOrderDetails /> },

  { name: 'tax', path: 'tax', element: <Tax /> },
  { name: 'uom', path: 'uom-list', element: <UomList /> },
  { name: 'reports', path: 'reports', element: <Report /> },
  { name: 'reports-sales-by-customer', path: 'reports/sales-by-customer', element: <SalesByCustomer /> },
  { name: 'reports-sales-by-item', path: 'reports/sales-by-item', element: <SalesByItem /> },
  { name: 'reports-purchases-by-item', path: 'reports/purchases-by-item', element: <PurchasesByItem /> },
  { name: 'reports-top-selling-product', path: 'reports/top-selling-product', element: <TopSellingProduct /> },
  { name: 'reports-low-stock-product', path: 'reports/low-stock-product', element: <LowStockProduct /> },
  { name: 'reports-payment-received', path: 'reports/payment-received', element: <PaymentReceived /> },
  { name: 'reports-payment-received', path: 'reports/payment-made', element: <PaymentMade /> },

  { name: 'reports-refund-history', path: 'reports/refund-history', element: <RefundHistory /> },

  { name: 'profit', path: 'profit', element: <Profit /> },

  { name: 'organization', path: 'organization', element: <Organization /> },

]

function App() {
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });
  const [roleName, setRoleName] = useState("admin");

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const { auth } = useSelector((state) => state?.root);

  useEffect(() => {
    if (pathname == "/" && user && user?.role && user?.role) {
      Navigate("/vendor");
    }
    setRoleName(user && user?.role);
  }, [user, pathname]);

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: '',
      head: '',
      id: ''
    });
    localStorage.removeItem('token');
    dispatch(logoutApi());

    Navigate('/');
  }

  useEffect(() => {
    if (auth && auth?.isExpired) {
      setIsModalOpen({
        open: true,
        currentComponent: "logout",
        para: 'Logged out due to inactivity. Please log in again to continue.',
        head: 'Session Expired',
      });
    }
  }, [auth]);

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      dispatch(loginUserApi(res?.data));
    }
  };


  useEffect(() => {
    if (pathname !== "/") {
      const token = localStorage.getItem("token");
      const payload = {
        token: token,
      };
      getUsetData(payload);
    }

  }, []);


  useEffect(() => {
    if (show && show.show) {
      setShowNotification(show);
    }
  }, [show]);


  return (
    <>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <div className="background_color">
            <Suspense fallback={<Loader />} >
              <Routes>
                <Route path="/" element={<Login />} />

                {pages?.map((page, index) => {
                  const { name, path, element } = page;
                  const permission =
                    user &&
                    user.permission &&
                    user.permission.find((permission) => {
                      return permission.pageName == name;
                    });

                  if ((permission && permission.readP === 1) || user.role == 1) {
                    return (
                      <Route key={index} exact path={`/${path}`} name={name} element={
                        <PrivateRoute>
                          <Layout>{element}</Layout>
                        </PrivateRoute>
                      }
                      />
                    )
                  }
                  else if (permission && permission.readP === 2) {
                    return (
                      <Route key={index} path={path} element={<h1>Not Found</h1>} />
                    );
                  }
                })}
              </Routes >
            </Suspense>
          </div>
          {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}
          <Modal
            modalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleClose={() => {
              return;
            }}
            handelClick={handelLogout}
          />
        </ThemeProvider>
      </ScrollToTop>

    </>

  );
}

export default App;
