import { deleteRequest, get, post, put } from "../web.request";


export const taxDropdown = async () => {
  return await get(`/taxDropdown`);
}

export const taxList = async (query) => {
  return await get(`/taxList${query}`);
}

export const changeTaxStatus = async (id) => {
  return await put(`/statusChangeTax/${id}`);
}

export const deleteTax = async (id) => {
  return await deleteRequest(`/deleteTax/${id}`);
}

export const taxDetail = async (id) => {
  return await get(`/taxDetail/${id}`);
}

export const taxUpdate = async (id, data) => {
  return await put(`/updateTax/${id}`, data);
}

export const taxCreate = async (data) => {
  return await post(`/addTax`, data);
}

