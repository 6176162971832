import React, { useEffect, useState } from 'react'
import { Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Stack, Box, Chip } from '@mui/material'
import { Button, Heading, Modal, MenuButton } from '../../components/Common'
import { useNavigate } from 'react-router-dom'
import { Pagination, Search } from '../../components/Common/Table'
import { changeProductStatus, deleteProduct, productList } from '../../Service/product.service'
import moment from 'moment'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'
import TableRowsLoader from '../../components/Common/Loader/Skeleton'
import { filterValueProductInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { AdjustMentList } from '../../Service/Adjustment.service'
import { AiOutlineEdit } from 'react-icons/ai'
import { HiOutlineTrash } from 'react-icons/hi'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import { IoAdd, IoEyeOutline } from 'react-icons/io5'


const InventoryAdjustment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [inventoryListData, setInventoryListData] = useState([]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0)
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState('updatedAt');
  const [sortBy, setSortBy] = useState(false);
  const [sortProduct, setSortProduct] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(20); // Default maximum characters

  const GetAdjustmentList = async () => {
    let query = `?search=${search}&page=${page + 1}&perPageSize=${rowsPerPage}`;
    let res = await AdjustMentList(query);
    if (res?.status) {
      setInventoryListData(res?.data);
      setTotalData(res?.total);
    } else {
      setInventoryListData(null);
    }
  }

  const formik = useFormik({
    initialValues: filterValueProductInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false);
      GetAdjustmentList();
    },
  });

  useEffect(() => {
    GetAdjustmentList();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == "status" ? await changeProductStatus(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await deleteProduct(isModalOpen?.id) : null;
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || t('Success')
      }
      ))
      GetAdjustmentList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || t('Something_went_wrong_Please_try_again_later')
      }
      ))
    }
  }

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'date':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        setSortProduct(false)
        break;
      case 'product':
        setSortBy(!by)
        setSortDate(false)
        setSortProduct((current) => !current)
        setShortKey('productName')
        break;
      default:
        setSortBy(!sortProduct)
        setSortDate(false)
        setShortKey('productName')
        setSortProduct(false)
        break;
    }
  };

  useEffect(() => {
    // Update max characters based on screen width
    const updateMaxCharacters = () => {
      if (window.innerWidth < 768) {
        setMaxCharacters(20); // Set max characters for smaller screens
      } else {
        setMaxCharacters(30); // Set max characters for larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxCharacters);

    // Initial update
    updateMaxCharacters();

    // Cleanup
    return () => window.removeEventListener('resize', updateMaxCharacters);
  }, []);


  const orderStatusMapping = {
    0: { label: "Draft", color: "default" },
    1: { label: "Adjusted", color: "success" }
  };

  const OrderStatusCell = ({ orderStatus }) => {
    // Get the status object from the mapping or default to "Unknown"
    const status = orderStatusMapping[orderStatus] || { label: "Unknown", color: "default" };

    return (
      <TableCell className='table-hover'>
        <Chip label={status.label} color={status.color} />
      </TableCell>
    );
  };

  return (
    <div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Heading head={t('InventoryAdjustment')} />
      </Stack>
      <Stack mb={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Search search={search} setSearch={setSearch} setPage={setPage} />
        <Button buttonName={t('Create')} onClick={() => navigate('/add-inventory-adjustment')} size='small' />
        {/* <Button buttonName={<VscFilter />} onClick={toggleDrawer} style={{ padding: '10px 11px' }} color='white' size='small' />
        <InventoryFilter
          value={value}
          setValue={setValue}
          openDrawer={openDrawer} toggleDrawer={toggleDrawer} formik={formik} /> */}
      </Stack>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('product', sortProduct)}>
                <Stack flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'start'}>
                  <div>{t("Date")}</div>
                  {sortProduct ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </Stack>
              </TableCell>
              <TableCell style={{ cursor: 'pointer' }} >{t("Reason")}
              </TableCell>
              <TableCell style={{ cursor: 'pointer' }} >{t("Status")}
              </TableCell>
              <TableCell style={{ cursor: 'pointer' }} >{t("CreatedBy")}
              </TableCell>
              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('date', sortDate)}>
                <Stack flexDirection={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
                  <div>{t("CreatedDate")}</div>
                  {sortDate ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </Stack>
              </TableCell>
              <TableCell>{t("LastModifyBy")}</TableCell>
              <TableCell>{t("LastModifiyDate")}</TableCell>
              <TableCell>{t("Action")}</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryListData && inventoryListData.length > 0 && inventoryListData?.map((data, i) => {
              return (
                <TableRow key={i} onClick={() => navigate('/view-inventory-adjustment', { state: data })}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                  <TableCell className='table-hover' >{moment(data?.date).format('DD-MM-YYYY')}</TableCell>
                  <TableCell className='table-hover' title={data.reason}>{data?.reason}</TableCell>
                  <OrderStatusCell orderStatus={data?.status} />
                  <TableCell className='table-hover' title={data?.createdBy}>{data?.createdBy}</TableCell>
                  <TableCell>{moment(data?.createdAt).format('DD-MM-YYYY')}</TableCell>
                  <TableCell>{data.updatedBy ?? "-"}</TableCell>
                  <TableCell>{data.updatedAt != null ? moment(data?.updatedAt).format('DD-MM-YYYY') : "-"}</TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger className='chatDropDown'>
                        <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content className='chatDropDownContent' align='end'>
                        <DropdownMenu.Item className='chatDropDownMenu' onClick={() => navigate('/view-inventory-adjustment', {
                          state: {
                            id: data?.id,
                            isEdit: false
                          }
                        })}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><IoEyeOutline style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '16px' }}>{t("View")}</Box></Stack>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className='chatDropDownMenu' onClick={() => navigate('/update-inventory-adjustment', {
                          state: {
                            id: data?.id,
                            isEdit: true
                          }
                        })}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '16px' }}>{t("Edit")}</Box></Stack>
                        </DropdownMenu.Item>

                      </DropdownMenu.Content>
                    </DropdownMenu.Root>
                  </TableCell>
                </TableRow>
              )
            })}

            {inventoryListData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  {t("No_records_to_display")}
                </TableCell>
              </TableRow>

            ) : (
              inventoryListData && inventoryListData.length === 0 ? (<TableRowsLoader colNumber={8} rowsNum={10} />) : ("")
            )}

          </TableBody>
        </Table>
      </TableContainer>
      {
        inventoryListData != null && (
          <Pagination totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )
      }
      <Modal
        modalOpen={isModalOpen}
        handelClick={changeStatus}
        handleClose={() =>
          setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
        } />
    </div >
  )
}

export default InventoryAdjustment