import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, FileUpload, Input, Select } from '../../../components/Common'
import Notification from '../../../components/Common/Notification/Notification'
import { useTranslation } from 'react-i18next'

const InvoiceModal = ({ handleClose, showNotification, setShowNotification, data, formik, modalOpen, isLoading }) => {
  const { t } = useTranslation()
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("ConverttoInvoice")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} width={{ sm: '500px' }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('InvoiceNumber')}
                name='invoiceNumber'
                maxLength={15}
                formik={formik}
              />)}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('InvoiceDate')}
                name='invoiceDate'
                maxLength={10}
                formik={formik}
                type='date'
                isDate={true}
              />)}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('DueDate')}
                name='dueDate'
                min={formik.values.billDate}
                maxLength={10}
                formik={formik}
                type='date'
                isDate={true}
              />)}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('PaymentTerms')}
                name='paymentTerms'
                maxLength={100}
                formik={formik}
              />)}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? t('Save') : t('Create')}
          size='small' onClick={formik.handleSubmit} disabled={isLoading} />
      </DialogActions>
      <Notification show={showNotification} setShow={setShowNotification} />
    </>
  )
}

export default InvoiceModal