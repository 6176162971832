import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, TextField, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, Input, Select } from '../../../components/Common'
import { notificationSuccess } from '../../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'
import Notification from '../../../components/Common/Notification/Notification'
import { useTranslation } from 'react-i18next'

const PaymentModal = ({ handleClose, data, formik, modalOpen, isLoading }) => {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {t("Addayment")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} width={{ sm: '500px' }} paddingY={1}>

          <Grid item xs={12} sm={12}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Select
              labelinput={t("PaymentType")}
              selectHead={t("None")}
              name='paymentType'
              value={formik.values.paymentType}
              formik={formik}
              onChange={(e) => {
                formik.setFieldValue('paymentType', e.target.value);
              }}
              selectList={[{
                id: 'Cash',
                name: t('Cash')
              }, {
                id: 'Cheque',
                name: t('Cheque')
              },
              {
                id: 'Card',
                name: t('Card')
              }, {
                id: 'UPI',
                name: t('UPI')
              }]}
            />)}
          </Grid>

          <Grid item xs={12} sm={12}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
              <Input labelinput={t('PaymentDate')}
                name='paymentDate'
                formik={formik}
                type='date'
                isDate={true}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12}>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('DueAmount')}
              name='dueAmount'
              maxLength={15}
              formik={formik}
              disabled={true}
            />)}
          </Grid>
          <Grid item xs={12} sm={12}>

            <Typography fontSize={{ xs: '10px', sm: '13px' }} fontWeight={500} mb={'2px'}>{t("PaymentAmount")}</Typography>

            {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<TextField value={formik.values.paymentAmount ?? 0}
              onChange={(e) => {
                if (e.target.value.match(/^[0-9]*$/) && parseFloat(formik.values.dueAmount) >= parseFloat(e.target.value)) {
                  let value = e.target.value;
                  formik.setFieldValue('paymentAmount', value.replace(/^0+/, ''));
                }
                else if (parseFloat(formik.values.dueAmount) < parseFloat(e.target.value) && e.target.value !== "") {
                  setShowNotification({
                    show: true,
                    type: 'error',
                    message: t('Payment_amount_should_be_less_than_due_amount')
                  })
                }
                else {
                  formik.setFieldValue('paymentAmount', e.target.value == "" ? 0 : formik.values.paymentAmount);
                }
              }}
              fullWidth
              color="primary"
              inputProps={{ maxLength: 15 }}
            />)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? t('Save') : t('Create')}
          size='small' onClick={formik.handleSubmit} disabled={isLoading} />
      </DialogActions>
      <Notification show={showNotification} setShow={setShowNotification} />
    </>
  )
}

export default PaymentModal