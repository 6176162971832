import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Autocomplete, BackButton, Button, Heading, Input, Modal, Select } from '../../components/Common'
import { useNavigate } from 'react-router-dom';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { purchaseOrderCreate, purchaseOrderUpdate, purchaseOrderDetail } from '../../Service/Purchase.service';
import { debitNoteInitialValues } from '../../helper/initialValues';
import { debitNoteValidationSchema } from '../../helper/validation';
import { useLocation } from 'react-router-dom';
import { productDropdown } from '../../Service/product.service';
import { MdAdd } from 'react-icons/md';
import { HiOutlineTrash } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { vendorDropdown } from '../../Service/Vendor.service';
import { useTranslation } from 'react-i18next';
import { CreditNoteAdd } from '../../Service/creditNote.service';

const AddVendorCredit = () => {

  const Navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [deletedProductIds, setDeletedProductIds] = useState([]);
  const [change, setChange] = useState(false);
  const [files, setFiles] = useState([]);
  const [isEdit, setIsEdit] = useState({ isEdit: false, id: '' });
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", class: '' });
  const [items, setItems] = useState([{ ids: uuidv4(), product: '', perProductPrice: 0, quantity: 0, amount: 0 }]);

  const addItem = () => {
    const newItem = {
      ids: uuidv4(),
      product: '',
      perProductPrice: 0,
      quantity: 0,
      amount: 0,
    };
    setItems([...items, newItem]);
  };

  const removeItem = (itemId) => {
    setItems(items.filter(item => item.ids !== itemId));

    if (items.find(item => item.ids === itemId).id) {
      setDeletedProductIds([...deletedProductIds, items.find(item => item.ids === itemId).id]);
    }
  };

  const handleNumericChange = (index, field, value) => {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
      handleInputChange(index, field, parseFloat(value));
      setChange(!change);
    } else {
      handleInputChange(index, field, 0);

    }
  };

  const handleInputChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };


  const formik = useFormik({
    initialValues: debitNoteInitialValues,
    onSubmit: async (value) => {
      if (!validateItems(items)) {
        return;
      }
      console.log('items', value.status);

      const payload = {
        "creditDate": value.creditDate,
        "vendorId": value.vendorId,
        "creditNo": value.creditNo,
        "subtotal": value.subTotal,
        "discount": value.discount == "" ? 0 : parseFloat(value.discount),
        "total": value.total,
        "creditDate": value.creditDate,
        "status": value.status == "" ? 1 : 0,
        "adjustment": value.adjustment == "" ? 0 : parseFloat(value.adjustment),
        "adjustmentType": value.adjustmentType,
        "note": value.note,
        "products": items?.map((item) => {
          return {
            "productId": item.product.id,
            "id": item.id,
            "quantity": parseInt(item.quantity),
            "rate": parseFloat(item.perProductPrice),
            "amount": parseInt(item.quantity) * parseFloat(item.perProductPrice)
          }
        }),
        status: value.status
        // deletedProductIds: deletedProductIds
      }

      console.log('payload', payload);

      let res = isEdit?.isEdit ? await purchaseOrderUpdate(isEdit?.id, payload) : await CreditNoteAdd(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success'
        }))
        formik.resetForm();
        Navigate('/vendor-credit')
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || t('Something_went_wrong_Please_try_again_later')
        }
        ))
      }
    },
    validationSchema: debitNoteValidationSchema,
  });


  useEffect(() => {
    setIsLoading(true);
    if (state && state?.isEdit && state?.id) {
      setIsEdit({ isEdit: true, id: state.id });
      getPurchaseOrderDetails(state.id);
    } else {
      setIsEdit({ isEdit: false, id: '' });
      setIsLoading(false);
    }
  }, [state]);

  useEffect(() => {

    let perProductPrice = items.reduce((acc, item, index) => {
      const productPrice = isNaN(parseFloat(item.perProductPrice)) ? 0 : parseFloat(item.perProductPrice);
      const quantity = isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity);
      return acc + (productPrice * quantity);
    }, 0);

  }, [items]);

  useEffect(() => {
    let perProductPrice = items.reduce((acc, item) => {
      const productPrice = isNaN(parseFloat(item.perProductPrice)) ? 0 : parseFloat(item.perProductPrice);
      const quantity = isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity);

      return acc + (productPrice * quantity);
    }, 0);
    let total = items.reduce((acc, item) => {
      const productPrice = isNaN(parseFloat(item.perProductPrice)) ? 0 : parseFloat(item.perProductPrice);
      const quantity = isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity);

      return acc + (productPrice * quantity);
    }, 0);


    formik.setFieldValue('subTotal', parseFloat(perProductPrice));
    formik.setFieldValue('total', parseFloat(total));

    let discount = formik.values.discount == "" || isNaN(formik.values.discount) ? 0 : parseFloat(formik.values.discount);
    let adjustment = formik.values.adjustment == "" || isNaN(formik.values.adjustment) ? 0 : parseFloat(formik.values.adjustment);
    let payableAmount = (perProductPrice) - discount;
    if (formik.values.adjustmentType == 2) {
      payableAmount = payableAmount - adjustment;
    }
    else {
      payableAmount = payableAmount + adjustment;
    }
    formik.setFieldValue('total', payableAmount.toFixed(2));

  }, [formik.values.quantity, change, formik.values.adjustmentType, formik.values.pricePerProduct, formik.values.discount, formik.values.adjustment]);

  const validateItems = (items) => {

    if (items && items == "undefined" && !items.length && items.length == 0) {
      return false; // Validation fails if there are no items
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const trimmedProduct = item.product && item.product.name || '';
      const trimmedPrice = item.perProductPrice || 0;
      const trimmedQty = item.quantity

      if (trimmedProduct === '' && trimmedPrice == 0 && trimmedQty == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Please_fill_in_all_the_fields')
        }));
        return false; // Validation fails if key is empty
      } else if (trimmedProduct == '') {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Product_is_required')
        }));
        return false; // Validation fails if key is empty
      }
      else if (trimmedPrice == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Price_is_required')
        }));
        return false; // Validation fails if key is empty
      }
      else if (trimmedQty == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Quantity_is_required')
        }));
        return false; // Validation fails if key is empty
      }

    }
    return true; // Validation passes if all items have non-empty key and value
  };


  const getPurchaseOrderDetails = async (id) => {
    const res = await purchaseOrderDetail(id);
    if (res?.status) {
      formik.setValues({
        vendorId: res.data.venderId.id,
        vendor: {
          id: res.data.venderId.id,
          name: res.data.venderId.name,
        },
        creditNo: res.data.creditNo,
        creditDate: moment(res.data.creditDate).format("YYYY-MM-DD"),
        subTotal: res.data?.subtotal,
        status: res.data?.status,
        discount: res.data?.discount,
        adjustment: res.data?.adjustment,
        total: res.data?.total,
        adjustmentType: res.data?.adjustmentType,

      });
      setItems(res?.data?.productId.map((item) => {
        return {
          ids: uuidv4(),
          product: {
            id: item.id,
            name: item.productId?.productName
          },
          id: item.id,
          perProductPrice: parseFloat(item.perProductPrice),
          uom: item.productId?.uom,
          quantity: item.quantity,
          amount: parseFloat(item.perProductPrice) * parseInt(item.quantity)
        }
      }
      ));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      formik.resetForm();
    }
  }

  const getproductData = async () => {
    const res = await productDropdown();
    if (res?.status) {
      let data = res.data.map((item) => {
        return {
          id: item.id,
          name: item.productName,
          uom: item.uom,
          costPrice: item.costPrice,
          salesPrice: item.salesPrice
        }
      }
      )
      setProductData(data);
    } else {
      setProductData([]);
    }
  }

  const getVendorData = async () => {
    const res = await vendorDropdown();
    if (res?.status) {
      setVendorData(res.data);
    } else {
      setVendorData([]);
    }
  }

  useEffect(() => {
    getproductData();
    getVendorData();
  }, []);

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <BackButton />
        <Heading head={`${isEdit?.isEdit ? 'Edit' : 'Create'} Credit Note`} />
      </Stack>
      <Box className='card_container' p={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack className='create_purchase_vendor border_card' gap={1} p={2}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                  <Input labelinput='Credit Date'
                    name='creditDate'
                    maxLength={50}
                    variant='standard'
                    formik={formik}
                    type='date'
                    isDate={true}
                  />
                )}
              </Stack>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                  <Input labelinput='Credit Note No'
                    name='creditNo'
                    maxLength={50}
                    variant='standard'
                    formik={formik}
                  />
                )}
              </Stack>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                  <Autocomplete
                    data={vendorData}
                    variant='standard'
                    labelinput='Vendor'
                    onChange={(e, val) => {
                      if (val) {
                        formik.setFieldValue('vendor', val);
                        formik.setFieldValue('vendorId', val.id);
                        formik.touched.product = false;
                      } else {
                        formik.setFieldValue('vendor', '');
                        formik.setFieldValue('vendorId', '');
                      }
                    }}
                    name='vendorId'
                    name1='vendor'
                    formik={formik}
                    value={formik.values.vendor}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} mb={1} alignItems={'flex-end'}>
              <Heading smallHead={'Product Details'} />
            </Stack>
            <Box className='border_card' p={2}>
              {items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Stack flexDirection={'row'} gap={1} alignItems={'flex-end'}>
                    <Box width={'100%'}>
                      <Autocomplete
                        style={{ width: '300px' }}
                        data={productData}
                        labelinput={index == 0 ? 'Product' : ''}
                        name='product'
                        onChange={(event, newValue) => {
                          handleInputChange(index, 'uom', newValue.uom);
                          handleInputChange(index, 'product', newValue)
                          handleNumericChange(index, 'perProductPrice', newValue.costPrice);
                        }}
                        value={item.product}
                      />
                    </Box>

                    <Box width={'100%'}>
                      <Input
                        placeholder='Rate'
                        value={item.perProductPrice}
                        labelinput={index == 0 ? 'Rate' : ''}
                        onChange={(e) => handleNumericChange(index, 'perProductPrice', e.target.value)}
                      />
                    </Box>

                    <Box width={'100px'}>
                      <Input
                        style={{ width: '100px' }}
                        placeholder='Quantity'
                        labelinput={index == 0 ? 'Quantity' : ''}
                        value={item.quantity}
                        onChange={(e) => handleNumericChange(index, 'quantity', e.target.value)}
                      />
                    </Box>
                    <Box width={'100px'}>
                      <Input
                        style={{ width: '100px' }}
                        placeholder='UOM'
                        labelinput={index == 0 ? 'UOM' : ''}
                        value={item.uom}
                        disabled={true}
                      />
                    </Box>

                    <Box width={'100%'}>
                      <Input
                        placeholder='Amount'
                        labelinput={index == 0 ? 'Amount' : ''}
                        value={(parseFloat(item.perProductPrice) * parseInt(item.quantity))}
                        disabled={true}
                        onChange={(e) => handleNumericChange(index, 'amount', e.target.value)}
                      />
                    </Box>
                    {items.length - 1 === index ?
                      <Button style={{ padding: '10px 11px' }} buttonName={<MdAdd style={{ fontSize: '19px' }} />} onClick={addItem} />
                      : <Button style={{ padding: '10px 11px' }} buttonName={<HiOutlineTrash style={{ fontSize: '19px' }} />} onClick={() => removeItem(item.ids)} />
                    }
                  </Stack>
                  {items.length - 1 === index ? '' : <Divider sx={{ marginY: 2 }} />}
                </React.Fragment>
              ))}
            </Box>
          </Grid>


          <Grid item xs={6}>
            <Input
              labelinput="Note"
              multiline
              name='note'
              formik={formik}
              rows={2}
            />
          </Grid>
          <Grid item xs={6}>
            <Box className='border_card' p={2}>

              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <div className='price_input'>
                    {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Subtotal'
                      variant='standard'
                      name='subTotal'
                      maxLength={10}
                      formik={formik}
                      disabled={true}
                    />)}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='price_input'>
                    {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Discount Amount'
                      name='discount'
                      variant='standard'
                      maxLength={10}
                      formik={formik}
                    />)}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                    <div className='price_input_main'>
                      <Typography fontSize={{ xs: '10px', sm: '13px' }} fontWeight={500}>Adjustment</Typography>
                      <div className='price_input_adjustment price_input' style={{ position: 'relative' }}>
                        <Select selectList={[{ name: '+', id: 1 }, { name: '-', id: 2 }]} className='input_select' onChange={(e) => {
                          formik.setFieldValue('adjustmentType', e.target.value);
                        }}
                          variant='standard'
                          value={formik.values.adjustmentType}
                          name='adjustmentType'
                          formik={formik}
                        />
                        <Input
                          variant='standard'
                          name='adjustment'
                          maxLength={10}
                          formik={formik}
                          className='select_input'
                        />
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className='price_input'>
                    {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput='Payable Amount'
                      name='total'
                      variant='standard'
                      maxLength={10}
                      formik={formik}
                      disabled={true}
                    />)}
                  </div>
                </Grid>
              </Grid>

            </Box>
          </Grid>
        </Grid>
        <Stack flexDirection={'row'} gap={1} mt={3} justifyContent={'flex-end'}>
          <Button buttonName='Discard' color='white' onClick={() => {
            Navigate('/vendor-credit');
          }} />
          {formik.values.status != 1 && <Button buttonName={`Save as Draft`}
            onClick={() => {
              formik.setFieldValue('status', 0);
              formik.handleSubmit();
            }}
            color='primary' />}
          <Button buttonName={`${isEdit?.isEdit ? 'Update' : 'Create'}`}
            onClick={formik.handleSubmit}
            color='primary' />
        </Stack>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        formik={formik}
        setFiles={setFiles}
        files={files}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "", class: '' })
          formik.resetForm()
        }} />
    </>
  )
}

export default AddVendorCredit