import { deleteRequest, get, post, put } from "../web.request";

export const paymentOrderData = async (id) => {
  return await get(`/venderPaymentList${id}`);
}

export const addPaymentMade = async (data) => {
  return await post('/addBulkPayment', data);
}

export const paymentList = async (query) => {
  return await get(`/paymentList${query}`);
}

export const paymentDetail = async (id) => {
  return await get(`/paymentDetail/${id}`);
}