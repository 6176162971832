import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Autocomplete, BackButton, Button, Heading, Input, Modal, Select } from '../../components/Common'
import { useNavigate } from 'react-router-dom';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { salesOrderCreate, salesOrderUpdate, salesOrderDetail } from '../../Service/Sales.service';
import { salesOrderInitialValues } from '../../helper/initialValues';
import { salesOrderValidationSchema } from '../../helper/validation';
import { useLocation } from 'react-router-dom';
import { productDropdown } from '../../Service/product.service';
import { CompanyDropdown } from '../../Service/Company.service';
import { MdAdd } from 'react-icons/md';
import { HiOutlineTrash } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { taxDropdown } from '../../Service/tax.service';
import { customerDropdown } from '../../Service/customer.service';
import { useTranslation } from 'react-i18next';

const SalesOrderAdd = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [deletedProductIds, setDeletedProductIds] = useState([]);
  const [change, setChange] = useState(false);
  const [files, setFiles] = useState([]);
  const [isEdit, setIsEdit] = useState({
    isEdit: false,
    id: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    class: ''
  });
  const [taxValues, setTaxValues] = useState([{ name: '', taxValue: 0, index: 0 }]);
  const [newItem, setNewItem] = useState([{ productId: '', quantity: 0, price: 0, taxName: "", taxValue: "" }]);
  const [items, setItems] = useState([{ ids: uuidv4(), product: '', perProductPrice: 0, quantity: 0, amount: 0, taxName: "", tax: "", taxValue: 0, }]);

  const addItem = () => {
    const newItem = {
      ids: uuidv4(),
      product: '',
      perProductPrice: 0,
      quantity: 0,
      amount: 0,
      tax: "",
      taxName: "",
      taxValue: 0,
    };
    setItems([...items, newItem]);
  };

  const removeItem = (itemId) => {
    setItems(items.filter(item => item.ids !== itemId));
    setTaxValues(
      taxValues
        .filter((item) => item.name != "")
        .reduce((acc, item) => {
          // Subtract the tax value from existing items in the accumulator array
          const existingItem = acc.find((accItem) => accItem.name == item.name);
          if (existingItem) {
            existingItem.taxValue -= item.taxValue;
          } else {
            acc.push(item);
          }
          return acc;
        }, [])
        .filter((item) => item.taxValue != 0) // Remove items with tax value 0
    );
    if (items.find(item => item.ids === itemId).id) {
      setDeletedProductIds([...deletedProductIds, items.find(item => item.ids === itemId).id]);
    }
  };

  const handleNumericChange = (index, field, value) => {
    if (!isNaN(value) && !isNaN(parseFloat(value))) {
      handleInputChange(index, field, parseFloat(value));
      setChange(!change);
    } else {
      handleInputChange(index, field, 0);
    }
  };

  const handleInputChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const formik = useFormik({
    initialValues: salesOrderInitialValues,
    onSubmit: async (value) => {
      if (!validateItems(items)) {
        return;
      }

      const payload = {
        "customerId": value.customerId,
        "orderDate": value.saleDate,
        "tax": value.taxValue == "" && value.taxValue == undefined ? 0 : parseFloat(value.taxValue),
        "subtotal": value.subTotal,
        "discount": value.discountAmount == "" && value.discountAmount == undefined ? 0 : parseFloat(value.discountAmount),
        "payableAmount": value.payableAmount,
        "adjustment": value.adjustment == "" ? 0 : parseFloat(value.adjustment),
        "adjustmentType": value.adjustmentType,
        "orderStatus": value.status == "draft" ? 0 : 1,
        "products": items?.map((item) => {
          return {
            "productId": item.product.id,
            "id": item.id,
            "quantity": parseInt(item.quantity),
            "price": parseFloat(item.perProductPrice),
            "taxName": item.taxName,
            "taxValue": item.taxValue,
            "tax": item.tax,
            "taxCalculateValue": (parseFloat(item.perProductPrice) * parseFloat(item.taxValue) * parseInt(item.quantity)) / 100,
          }
        }),
        deletedProductIds: deletedProductIds

      }

      let res = isEdit?.isEdit ? await salesOrderUpdate(isEdit?.id, payload) : await salesOrderCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || t('Success')
        }))
        formik.resetForm();
        Navigate('/sales-order')
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || t('Something_went_wrong_Please_try_again_later')
        }
        ))
      }
    },
    validationSchema: salesOrderValidationSchema,
  });

  useEffect(() => {
    setIsLoading(true);
    if (state && state?.isEdit && state?.id) {
      setIsEdit({ isEdit: true, id: state.id });
      getSalesOrderDetails(state.id);
    } else {
      setIsEdit({ isEdit: false, id: '' });
      setIsLoading(false);
    }
  }, [state]);

  useEffect(() => {
    setNewItem([]);
    let perProductPrice = items.reduce((acc, item, index) => {
      const productPrice = isNaN(parseFloat(item.perProductPrice)) ? 0 : parseFloat(item.perProductPrice);
      const quantity = isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity);
      const taxMultiplier = (productPrice * quantity * (parseFloat(item.taxValue))) / 100;
      taxValues[index] = {
        name: item.taxName,
        taxValue: taxMultiplier,
      };

      return acc + (productPrice * quantity * taxMultiplier);
    }, 0);

    setNewItem(items.map((item, index) => {
      return {
        productId: item.product.id,
        quantity: parseInt(item.quantity),
        price: parseFloat(item.perProductPrice),
        taxName: item.taxName,
        taxValue: (item.perProductPrice * item.quantity * (parseFloat(item.taxValue))) / 100,
        amount: (item.perProductPrice * item.quantity) + (item.perProductPrice * item.quantity * taxValues[index].taxValue),

      }
    }));
    setTaxValues(taxValues.filter((item) => item.name != "").reduce((acc, item) => {
      const existingItem = acc.find((accItem) => accItem.name === item.name);
      if (existingItem) {
        existingItem.taxValue += item.taxValue;
      } else {
        acc.push(item);
      }
      return acc;
    }, []));

  }, [items]);


  useEffect(() => {
    let perProductPrice = items.reduce((acc, item) => {
      const productPrice = isNaN(parseFloat(item.perProductPrice)) ? 0 : parseFloat(item.perProductPrice);
      const quantity = isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity);
      const tax = isNaN(parseFloat(item.taxValue)) ? 0 : productPrice * quantity * (parseFloat(item.taxValue)) / 100;

      return acc + (productPrice * quantity) + tax;
    }, 0);

    let total = items.reduce((acc, item) => {
      const productPrice = isNaN(parseFloat(item.perProductPrice)) ? 0 : parseFloat(item.perProductPrice);
      const quantity = isNaN(parseInt(item.quantity)) ? 0 : parseInt(item.quantity);
      const tax = isNaN(parseFloat(item.taxValue)) ? 0 : productPrice * quantity * (parseFloat(item.taxValue)) / 100;

      return acc + (productPrice * quantity);
    }, 0);

    console.log("total", formik.values.total);

    let tax = taxValues.reduce((acc, item) => {
      return acc + item.taxValue;
    }, 0);

    formik.setFieldValue('taxValue', tax.toFixed(2));
    formik.setFieldValue('total', parseFloat(total));

    formik.setFieldValue('subTotal', parseFloat(perProductPrice));
    let discountAmount = formik.values.discountAmount == "" || isNaN(formik.values.discountAmount) ? 0 : parseFloat(formik.values.discountAmount);
    let adjustment = formik.values.adjustment == "" || isNaN(formik.values.adjustment) ? 0 : parseFloat(formik.values.adjustment);
    let payableAmount = (perProductPrice) - discountAmount;
    if (formik.values.adjustmentType == 2) {
      payableAmount = payableAmount - adjustment;
    }
    else {
      payableAmount = payableAmount + adjustment;
    }
    formik.setFieldValue('payableAmount', payableAmount.toFixed(2));

  }, [formik.values.quantity, change, formik.values.adjustmentType, formik.values.pricePerProduct, formik.values.tax, formik.values.discountAmount, formik.values.adjustment, taxValues]);

  const validateItems = (items) => {

    if (items && items == "undefined" && !items.length && items.length == 0) {
      return false; // Validation fails if there are no items
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const trimmedProduct = item.product && item.product.name || '';
      const trimmedPrice = item.perProductPrice || 0;
      const trimmedQty = item.quantity
      const trimmedTax = item.taxName || '';

      if (trimmedProduct === '' && trimmedPrice == 0 && trimmedQty == 0 && trimmedTax == '') {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Please_fill_in_all_the_fields')
        }));
        return false; // Validation fails if key is empty
      } else if (trimmedProduct == '') {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Productisrequired')
        }));
        return false; // Validation fails if key is empty
      }
      else if (trimmedPrice == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Priceisrequired')
        }));
        return false; // Validation fails if key is empty
      }
      else if (trimmedQty == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Quantityisrequired')
        }));
        return false; // Validation fails if key is empty
      }
      else if (trimmedTax == 0) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: t('Taxisrequired')
        }));
        return false; // Validation fails if key is empty
      }
    }
    return true; // Validation passes if all items have non-empty key and value
  };


  const getSalesOrderDetails = async (id) => {
    const res = await salesOrderDetail(id);
    if (res?.status) {
      formik.setValues({
        customerId: res.data.customerData.id,
        customer: {
          id: res.data.customerData.id,
          name: res.data.customerData.name,
        },
        orderNumber: res.data.orderNumber,
        saleDate: moment(res.data.saleDate).format("YYYY-MM-DD"),
        taxValue: res.data?.taxValue,
        subTotal: res.data?.subtotal,
        discountAmount: res.data?.discount,
        adjustment: res.data?.adjustment,
        payableAmount: res.data?.payableAmount,
        adjustmentType: res.data?.adjustmentType,
        tax: res.data?.tax,

      });
      setItems(res?.data?.salesOrderProduct.map((item) => {
        return {
          ids: uuidv4(),
          product: {
            id: item.productId.id,
            name: item.productId?.productName
          },
          id: item.id,
          perProductPrice: parseFloat(item.perProductPrice),
          tax: item.tax,
          taxName: item.taxName,
          taxValue: item.taxValue,
          uom: item.productId?.uom,
          taxCalculateValue: (parseFloat(item.perProductPrice) * parseFloat(item.taxValue) * parseInt(item.quantity)) / 100,
          quantity: item.quantity,
          amount: parseFloat(item.perProductPrice) * parseInt(item.quantity)
        }
      }
      ));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      formik.resetForm();
    }
  }

  const getproductData = async () => {
    const res = await productDropdown();
    if (res?.status) {
      let data = res.data.map((item) => {
        return {
          id: item.id,
          name: item.productName,
          uom: item.uom,
          tax: item.tax,
          costPrice: item.costPrice,
          salePrice: item.salePrice
        }
      }
      )
      setProductData(data);
    } else {
      setProductData([]);
    }
  }

  const getXaxData = async () => {
    const res = await taxDropdown();
    if (res?.status) {
      let data = res.data && res?.data?.map((item) => {
        return {
          id: item.id,
          name: item.lable,
          taxValue: item.taxValue,
          lable: item.name
        }
      }
      )
      setTaxData(data);
    } else {
      setTaxData([]);
    }
  }

  const getcustomerData = async () => {
    const res = await customerDropdown();
    if (res?.status) {
      setCustomerData(res.data);
    } else {
      setCustomerData([]);
    }
  }


  useEffect(() => {
    getproductData();
    getcustomerData();
    getXaxData();
  }, []);



  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <BackButton />
        <Heading head={`${isEdit?.isEdit ? t('Edit') : t('Create')}  ${t("SalesOrder")}`} />
      </Stack>
      <Box className='card_container' p={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack className='create_purchase_vendor border_card' gap={1} p={2}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                  <Input labelinput={t('SaleDate')}
                    name='saleDate'
                    maxLength={50}
                    variant='standard'
                    formik={formik}
                    type='date'
                    isDate={true}
                  />
                )}
              </Stack>

              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                  <Autocomplete
                    data={customerData}
                    variant='standard'
                    labelinput={t('Customer')}
                    onChange={(e, val) => {
                      if (val) {
                        formik.setFieldValue('customer', val);
                        formik.setFieldValue('customerId', val.id);
                        formik.touched.product = false;
                      } else {
                        formik.setFieldValue('customer', '');
                        formik.setFieldValue('customerId', '');
                      }
                    }}
                    name='customerId'
                    name1='customer'
                    formik={formik}
                    value={formik.values.customer}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} mb={1} alignItems={'flex-end'}>
              <Heading smallHead={t('ProductDetails')} />
              {/* <Button buttonName='Upload Product' onClick={() => {
                setIsModalOpen({ open: true, currentComponent: "uploadProduct", class: 'upload_product_modal' })
                setFiles([]);
              }
              } /> */}
            </Stack>
            <Box className='border_card' p={2}>
              {items.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Stack flexDirection={'row'} gap={1} alignItems={'flex-end'}>
                    <Box width={'100%'}>
                      <Autocomplete
                        data={productData}
                        labelinput={index == 0 ? t('Product') : ''}
                        name='product'
                        onChange={(event, newValue) => {
                          handleInputChange(index, 'uom', newValue.uom);
                          handleInputChange(index, 'product', newValue)
                          handleInputChange(index, 'tax', taxData && taxData.find(item => item.id == newValue.tax).id);
                          handleInputChange(index, 'taxName', taxData && taxData.find(item => item.id == newValue.tax).lable);
                          handleInputChange(index, 'taxValue', taxData && taxData.find(item => item.id == newValue.tax).taxValue);
                          handleNumericChange(index, 'perProductPrice', newValue.salePrice);

                        }
                        }
                        value={item.product}
                      />
                    </Box>

                    <Box width={'100%'}>
                      <Input
                        placeholder={t('Rate')}
                        value={item.perProductPrice}
                        labelinput={index == 0 ? t('Rate') : ''}
                        onChange={(e) => handleNumericChange(index, 'perProductPrice', e.target.value)}
                      />
                    </Box>
                    <Box width={'100%'}>
                      <Input
                        placeholder={t('Quantity')}
                        labelinput={index == 0 ? t('Quantity') : ''}
                        value={item.quantity}
                        onChange={(e) => handleNumericChange(index, 'quantity', e.target.value)}
                      />
                    </Box>
                    <Box width={'100%'}>
                      <Input
                        placeholder={t('UOM')}
                        labelinput={index == 0 ? t('UOM') : ''}
                        value={item.uom}
                        disabled={true}
                      />
                    </Box>
                    <Box width={'100%'}>
                      <Select
                        labelinput={index == 0 ? t('Tax') : ''}
                        selectHead={t('None')}
                        name='tax'
                        formik={formik}
                        value={item.tax}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            handleInputChange(index, 'tax', "");
                            handleInputChange(index, 'taxName', "");
                            handleInputChange(index, 'taxValue', 0);
                          } else {
                            handleInputChange(index, 'tax', taxData && taxData.find(item => item.id == e.target.value).id);
                            handleInputChange(index, 'taxName', taxData && taxData.find(item => item.id == e.target.value).lable);
                            handleInputChange(index, 'taxValue', taxData && taxData.find(item => item.id == e.target.value).taxValue);
                          }

                        }}
                        selectList={taxData}
                      />
                    </Box>
                    <Box width={'100px'}>
                      <Input
                        style={{ width: '100px' }}
                        placeholder={t('TaxValue')}
                        labelinput={index == 0 ? t('TaxAmount') : ''}
                        value={(parseFloat(item.perProductPrice) * parseInt(item.quantity) * parseFloat(item.taxValue) / 100)}
                        disabled={true}
                      // onChange={(e) => handleNumericChange(index, 'uom', e.target.value)}
                      />
                    </Box>


                    <Box width={'100%'}>
                      <Input
                        placeholder={t('Amount')}
                        labelinput={index == 0 ? t('Amount') : ''}
                        value={(parseFloat(item.perProductPrice) * parseInt(item.quantity)) + (parseFloat(item.perProductPrice) * parseInt(item.quantity) * parseFloat(item.taxValue) / 100)}
                        disabled={true}
                        onChange={(e) => handleNumericChange(index, 'amount', e.target.value)}
                      />
                    </Box>
                    {items.length - 1 === index ?
                      <Button style={{ padding: '10px 11px' }} buttonName={<MdAdd style={{ fontSize: '19px' }} />} onClick={addItem} />
                      : <Button style={{ padding: '10px 11px' }} buttonName={<HiOutlineTrash style={{ fontSize: '19px' }} />} onClick={() => removeItem(item.ids)} />
                    }
                  </Stack>
                  {items.length - 1 === index ? '' : <Divider sx={{ marginY: 2 }} />}
                </React.Fragment>
              ))}
            </Box>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Box className='border_card' p={2}>
              <Grid item xs={12}>
                <div className='price_input'>
                  {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('Total')}
                    variant='standard'
                    name='total'
                    maxLength={10}
                    formik={formik}
                    disabled={true}
                  />)}
                </div>
              </Grid>
              <Grid container spacing={1}>

                {taxValues && taxValues.length > 0 && taxValues.map((item, index) => (
                  item.name != "" && <Grid item xs={12}>
                    <div className='price_input'>
                      {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={item.name}
                        variant='standard'
                        name={item.name}
                        maxLength={10}
                        value={item.taxValue}
                        disabled={true}
                      />)}
                    </div>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <div className='price_input'>
                    {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('Subtotal')}
                      variant='standard'
                      name='subTotal'
                      maxLength={10}
                      formik={formik}
                      disabled={true}
                    />)}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='price_input'>
                    {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('DiscountAmount')}
                      name='discountAmount'
                      variant='standard'
                      maxLength={10}
                      formik={formik}
                    />)}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (
                    <div className='price_input_main'>
                      <Typography fontSize={{ xs: '10px', sm: '13px' }} fontWeight={500}>{t("Adjustment")}</Typography>
                      <div className='price_input_adjustment price_input' style={{ position: 'relative' }}>
                        <Select selectList={[{ name: '+', id: 1 }, { name: '-', id: 2 }]} className='input_select' onChange={(e) => {
                          formik.setFieldValue('adjustmentType', e.target.value);
                        }}
                          variant='standard'
                          value={formik.values.adjustmentType}
                          name='adjustmentType'
                          formik={formik}
                        />
                        <Input
                          variant='standard'
                          name='adjustment'
                          maxLength={10}
                          formik={formik}
                          className='select_input'
                        />
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className='price_input'>
                    {isLoading ? (<Skeleton animation="wave" variant="text" width={'100%'} height={90} />) : (<Input labelinput={t('PayableAmount')}
                      name='payableAmount'
                      variant='standard'
                      maxLength={10}
                      formik={formik}
                      disabled={true}
                    />)}
                  </div>
                </Grid>
              </Grid>

            </Box>
          </Grid>
        </Grid>
        <Stack flexDirection={'row'} gap={1} mt={3} justifyContent={'flex-end'}>
          <Button buttonName={t('Discard')} color='white' onClick={() => {
            Navigate('/sales-order');
          }} />
          {!isEdit?.isEdit && <Button buttonName={t(`SaveasDraft`)}
            onClick={() => {
              formik.setFieldValue('status', 'draft');
              formik.handleSubmit();
            }}
            color='primary' />}
          <Button buttonName={`${isEdit?.isEdit ? t('Update') : t('Create')}`}
            onClick={formik.handleSubmit}
            color='primary' />

        </Stack>
      </Box>
      <Modal
        modalOpen={isModalOpen}
        formik={formik}
        setFiles={setFiles}
        files={files}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "", class: '' })
          formik.resetForm()
        }} />
    </>
  )
}

export default SalesOrderAdd