import * as Yup from 'yup';
import { uomCreate } from '../Service/Uom.service';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  password: Yup.string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters.')

});

export const addProductValidationSchema = Yup.object().shape({
  productName: Yup.string().required('Product Name is required.'),
  costPrice: Yup.string()
    .required('Cost Price is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Price must be a number with a maximum of 6 digits.'
    ),
  salePrice: Yup.string().required('Sale Price is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Price must be a number with a maximum of 6 digits.'
    ),

  tax: Yup.string().required('Tax is required.'),
  uom: Yup.string().required('UOM is required.')

});


export const addCategoryValidationSchema = Yup.object().shape({
  categoryName: Yup.string().required('Category Name is required.'),
});
export const addCountryValidationSchema = Yup.object().shape({
  countryName: Yup.string().required('Country Name is required.'),
});

export const addStateValidationSchema = Yup.object().shape({
  stateName: Yup.string().required('State Name is required.'),
  countryId: Yup.string().required('Country selection is required.'),
});

export const addCityValidationSchema = Yup.object().shape({
  cityName: Yup.string().required('City Name is required.'),
  stateId: Yup.string().required('State selection is required.'),
  countryId: Yup.string().required('Country selection is required.'),
});


export const addSubCategoryValidationSchema = Yup.object().shape({
  subCategoryName: Yup.string().required('Sub Category Name is required.'),
  categoryId: Yup.string().required('Category is required.'),
});

export const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
  confirmpass: Yup.string()
    .required('Confirm Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
  currentPassword: Yup.string().required('Password is required.'),
});

export const addAdminValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  role: Yup.string().required('Role is required.'),
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  zipCode: Yup.string().optional()
    .matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ),
});

export const roleAddValidationSchema = Yup.object().shape({
  roleName: Yup.string().required('Role Name is required.'),
});


export const addCompanyValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required.'),
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  phoneNumber: Yup.string().required('Phone Number is required.')
    .matches(
      /^[0-9]*$/,
      'Phone Number must be numeric.'
    ).max(10, 'Phone Number should have a max of 10 digits. Keep it in check!')
    .min(10, 'Phone Number should have a min of 10 digits. Keep it in check!'),
  vendorName: Yup.string().required('Vendor Name is required.')
    .matches(
      /^[a-zA-Z\s]*$/,
      'Vendor Name must be a character.'
    ),
  gstNumber: Yup.string().required('GST Number is required.')
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      'GST Number is not valid.'
    ),
  panNumber: Yup.string().required('PAN Number is required.')
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      'PAN Number is not valid.'
    ),
  addressLine1: Yup.string().required('Address Line 1 is required.'),
  stateId: Yup.string().required('State is required.'),
  cityId: Yup.string().required('City is required.'),
  zipCode: Yup.string().required('Zip Code is required.').
    matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),
});

export const addWarehouseValidationSchema = Yup.object().shape({
  warehouseName: Yup.string().required('Warehouse Name is required.'),
  companyName: Yup.string().required('Company Name is required.'),
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  phoneNumber: Yup.string().required('Phone Number is required.')
    .matches(
      /^[0-9]*$/,
      'Phone Number must be numeric.'
    ).max(10, 'Phone Number should have a max of 10 digits. Keep it in check!')
    .min(10, 'Phone Number should have a min of 10 digits. Keep it in check!'),
  ownerName: Yup.string().required('Owner Name is required.')
    .matches(
      /^[a-zA-Z\s]*$/,
      'Owner Name must be a character.'
    ),
  gstNumber: Yup.string().required('GST Number is required.')
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      'GST Number is not valid.'
    ),
  panNumber: Yup.string().required('PAN Number is required.')
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      'PAN Number is not valid.'
    ),
  addressLine1: Yup.string().required('Address Line 1 is required.'),
  stateId: Yup.string().required('State is required.'),
  cityId: Yup.string().required('City is required.'),
  zipCode: Yup.string().required('Zip Code is required.').
    matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),
});

export const useeAddressValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
});


export const purchaseOrderValidationSchema = Yup.object().shape({
  vendorId: Yup.string().required('Vendor selection is required.'),
  venderIncNo: Yup.string().required('Invoice Number is required.'),
  purchaseDate: Yup.string().required('Purchase Date is required.'),

  discountAmount: Yup.string().optional('Discount Amount is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Discount Amount must be a number with a maximum of 6 digits.'
    ),
  adjustment: Yup.string().optional('Adjustment is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Adjustment must be a number with a maximum of 6 digits.'
    ),

  //if payment value true then payment type required and then payment type value 1 then transaction id required payment type 2 then cheque number reuired 
  // payment: Yup.string(),
  // paymentType: Yup.string(),

  // paymentDate: Yup.string().when('paymentType', (paymentType, schema) => {
  //   return paymentType != '' ? schema.required('Payment Date is required.') : schema;
  // }
  // ),

  // paymentAmount: Yup.string().when('payment', (paymentType, schema) => {
  //   return paymentType != '' ? schema.required('Payment Amount is required.') : schema;
  // }
  // ),



});

export const debitNoteValidationSchema = Yup.object().shape({
  vendorId: Yup.string().required('Vendor selection is required.'),
  creditNo: Yup.string().required('Credit Number is required.'),
  creditDate: Yup.string().required('Credit Date is required.'),
  discount: Yup.string().optional('Discount Amount is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Discount Amount must be a number with a maximum of 6 digits.'
    ),
  adjustment: Yup.string().optional('Adjustment is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Adjustment must be a number with a maximum of 6 digits.'
    ),
});


export const creditNoteValidationSchema = Yup.object().shape({
  customerId: Yup.string().required('Customer selection is required.'),
  creditNo: Yup.string().required('Credit Number is required.'),
  creditDate: Yup.string().required('Credit Date is required.'),
  discount: Yup.string().optional('Discount Amount is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Discount Amount must be a number with a maximum of 6 digits.'
    ),
  adjustment: Yup.string().optional('Adjustment is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Adjustment must be a number with a maximum of 6 digits.'
    ),
});

export const salesOrderValidationSchema = Yup.object().shape({
  customerId: Yup.string().required('Customer selection is required.'),
  saleDate: Yup.string().required('Sale Date is required.'),
  discountAmount: Yup.string().optional('Discount Amount is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Discount Amount must be a number with a maximum of 6 digits.'
    ),
  adjustment: Yup.string().optional('Adjustment is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Adjustment must be a number with a maximum of 6 digits.'
    ),

});
export const adjustmentValidationSchema = Yup.object().shape({
  reason: Yup.string().required('Reason is required.'),
  date: Yup.string().required('Date is required.'),
});

export const paymentMadeValidationSchema = Yup.object().shape({
  paymentDate: Yup.string().required('Payment Date is required.'),
  paymentMode: Yup.string().required('Payment Mode is required.'),
  vendorId: Yup.string().required('Vendor selection is required.')
});
export const paymentRecienedValidationSchema = Yup.object().shape({
  paymentDate: Yup.string().required('Payment Date is required.'),
  paymentMode: Yup.string().required('Payment Mode is required.'),
  customerId: Yup.string().required('Customer selection is required.')
});

export const convertToBillValidationSchema = Yup.object().shape({
  billNumber: Yup.string().required('Bill Number is required.'),
  billDate: Yup.string().required('Bill Date is required.'),
  dueDate: Yup.string().required('Due Date is required.')
});


export const convertToInvoiceValidationSchema = Yup.object().shape({
  invoiceNumber: Yup.string().required('Invoice Number is required.'),
  invoiceDate: Yup.string().required('Invoice Date is required.'),
  dueDate: Yup.string().required('Due Date is required.')
});

export const purchaseOrderPaymentValidationSchema = Yup.object().shape({
  payment: Yup.string(),
  paymentType: Yup.string().required('Payment Type is required.'),
  paymentDate: Yup.string().required('Payment Date is required.'),
  paymentAmount: Yup.string().required('Payment Amount is required.')
    .matches(
      /^\d{1,11}(\.\d{1,5})?$/,
      'Payment Amount must be a number with a maximum of 9 digits.'
    ),
});

export const salesOrderPaymentValidationSchema = Yup.object().shape({
  payment: Yup.string(),
  paymentType: Yup.string().required('Payment Type is required.'),
  paymentDate: Yup.string().required('Payment Date is required.'),
  paymentAmount: Yup.string().required('Payment Amount is required.')
    .matches(
      /^\d{1,11}(\.\d{1,5})?$/,
      'Payment Amount must be a number with a maximum of 9 digits.'
    ),
});

export const profileUpdateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  phone: Yup.string().required('Phone Number is required.')
    .matches(
      /^[0-9]*$/,
      'Phone Number must be numeric.'
    ).max(10, 'Phone Number should have a max of 10 digits. Keep it in check!')
    .min(10, 'Phone Number should have a min of 10 digits. Keep it in check!'),

  zipCode: Yup.string().optional().
    matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),


});

export const organizationUpdateValidationSchema = Yup.object().shape({
  vatNumber: Yup.string().required('VAT Number is required.'),
  name: Yup.string().required('Name is required.'),
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  phone: Yup.string().required('Phone Number is required.')
    .matches(
      /^[0-9]*$/,
      'Phone Number must be numeric.'
    ).max(10, 'Phone Number should have a max of 10 digits. Keep it in check!')
    .min(10, 'Phone Number should have a min of 10 digits. Keep it in check!'),

  zipCode: Yup.string().optional().
    matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),


});


export const addPurchaseOrderValidationSchema = Yup.object().shape({
  userId: Yup.string().required('User selection is required.'),
  discountAmount: Yup.string().optional()
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Discount Amount must be a number with a maximum of 6 digits.'
    ),
  adjustment: Yup.string().required('Adjustment is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Adjustment must be a number with a maximum of 6 digits.'
    ),
  payableAmount: Yup.string().required('Payable Amount is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Payable Amount must be a number with a maximum of 6 digits.'
    ),

});

export const addVendorValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  name: Yup.string().required('First Name is required.'),
  companyName: Yup.string().required('Company Name is required.'),
  zipCode: Yup.string().optional()
    .matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),
  sZipCode: Yup.string().optional()
    .matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),
});

export const addCustomerValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address.').required('Email is required.'),
  name: Yup.string().required('First Name is required.'),
  companyName: Yup.string().required('Company Name is required.'),
  zipCode: Yup.string().optional()
    .matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),
  sZipCode: Yup.string().optional()
    .matches(
      /^[0-9]*$/,
      'Zip Code must be numeric.'
    ).max(6, 'Zip Code should have a max of 6 digits. Keep it in check!'),

});

export const addTaxValidationSchema = Yup.object().shape({
  name: Yup.string().required('Tax Name is required.'),
  taxValue: Yup.string().required('Tax Value is required.')
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      'Tax Value must be a number with a maximum of 6 digits.'
    ),
  label: Yup.string().required('Label is required.'),
});

export const addUomValidationSchema = Yup.object().shape({
  name: Yup.string().required('Tax Name is required.'),
});