import { deleteRequest, get, post, put } from "../web.request";

export const CreditNoteList = async (query) => {
  return await get(`customerCreditList${query}`);
}

export const CreditNoteAdd = async (data) => {
  return await post(`/addCustomerCredit`, data);
}

export const CreditNoteDetails = async (id) => {
  return await get(`/customerCreditDetail/${id}`);
}

export const CreditNoteStatusChange = async (id, data) => {
  return await put(`/customerCreditStatusChange/${id}`, data);
}

//customerCreditApply
export const CreditNoteApply = async (data) => {
  return await post(`/customerCreditApply`, data);
}

// customerINvoiceList
export const customerinvoiceList = async (query) => {
  return await get(`customerInvoiceList${query}`);
}