import { deleteRequest, get, post, put } from "../web.request";

export const purchaseOrderListData = async (query) => {
  return await get(`/purchaseOrderList${query}`);
}

export const purchaseOrderCreate = async (data) => {
  return await post(`/addPurchaseOrder`, data);
}

export const purchaseOrderUpdate = async (id, data) => {
  return await put(`/updatePurchaseOrder/${id}`, data);
}

export const purchaseOrderDetail = async (id) => {
  return await get(`/purchaseOrderDetail/${id}`);
}

export const statusChangeDrafttoConfirm = async (id) => {
  return await put(`/orderStatusChange/${id}`);
}

export const purchaseOrderPayment = async (data) => {
  return await post(`/addPayment`, data);
}

//vendor dropdown for purchase order
export const vendorDropdownForPurchase = async () => {
  return await get(`/venderOrderDropdown`);
}