import React from 'react'
import { FaUserLarge } from '../../helper/Icons'
import { Box, Stack, Typography } from '@mui/material'
import Button from './Button'

const UploadImage = () => {

    return (
        <>
            <Stack flexDirection={{ xs: 'column', lg: 'row' }} gap={{ xs: 2, sm: 4 }} justifyContent={'space-between'}>
                <Stack flexDirection={{ xs: 'column', sm: 'row' }} gap={{ xs: 2, sm: 4 }} alignItems={{ sm: 'center' }}>
                    <Box sx={{ backgroundColor: '#ede9e6', borderRadius: '15px', width: { xs: '90px', sm: '110px' }, height: { xs: '90px', sm: '110px' }, padding: '8px' }}>
                        <FaUserLarge className='image_upload_icon' style={{ borderRadius: '0', color: '#00BFF5' }} />
                    </Box>
                    <Stack gap={2}>
                        <Stack flexDirection={{ xs: 'row', sm: 'row' }} gap={{ xs: 1, sm: 2 }}>
                            <Button buttonName='Upload Profile Photo' size='small' />
                            <Button buttonName='Reset' color='white' size='small' />
                        </Stack>
                        <Typography fontSize={'18px'} sx={{ color: '#aaa' }}>Allowed Jpg,png</Typography>
                    </Stack>
                </Stack>
            </Stack>

        </>
    )
}

export default UploadImage