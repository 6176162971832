import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BackButton, Button, Heading, Modal } from '../../components/Common'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/Nettyfy-Logo-01-06.png'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MdDownloading } from 'react-icons/md'
import { CreditNoteStatusChange, CreditNoteDetails } from '../../Service/Debit.service'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'

const ViewCreditNote = () => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", class: '' });
  const [creditNoteData, setCreditNoteData] = React.useState({})

  useEffect(() => {
    if (state && state?.id) {
      GetCreditNoteData(state.id)
    }
  }, [])

  const GetCreditNoteData = async (id) => {
    const res = await CreditNoteDetails(id)
    if (res?.status) {
      setCreditNoteData(res?.data)
    } else {
      setCreditNoteData({})
    }
  }

  const statuschange = async () => {
    let res = await CreditNoteStatusChange(state.id)
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || t('Success')
      }))
      setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
      GetCreditNoteData(state.id)
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || t('Something_went_wrong_Please_try_again_later')
      }))
    }
  }

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <BackButton />
          <Heading head={t('Customer Credit Details')} />
        </Stack>

        <Stack>
          {creditNoteData && creditNoteData?.status == 0 ?
            (
              <Button buttonName={t("MarkasConfirmed")} startIcon={<MdDownloading style={{ fontSize: '14px' }} />} onClick={() => {
                setIsModalOpen({ id: creditNoteData?.id, open: true, currentComponent: "status", head: t("ChangeStatus"), para: "Are_you_sure_you_want_to_change_status_draft_to_confirm" })
              }} />
            ) : (
              <Button buttonName='Apply to invoice' onClick={() => navigate('/apply-credit-to-invoice', {
                state: {
                  customerId: creditNoteData?.customerId?.id,
                  creditNoteId: creditNoteData?.id,
                  creditNumber: creditNoteData?.creditNumber,
                }
              })} />
            )}

        </Stack>
      </Stack>
      <div style={{
        marginTop: '50px',
      }} dangerouslySetInnerHTML={{
        __html: `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title></title>
  <style>
    @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

    body {
      font-family: "Montserrat", sans-serif !important;
    }

    .container {
      // margin: 0 auto;
      max-width: 210mm;
      /* A4 width */
      height: 275mm;
      /* A4 height */
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 70px 45px !important;
      position:relative;
      overflow: hidden;
    }

    .content {
      display: flex;
      justify-content: space-between;
    }

    .content-left {
      margin-top: 10px;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .content-right {
      width: 50%;
    }

    img {
      width: 100%;
      max-width: 250px;
    }

    h1 {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin: 0;
    }

    h2 {
      font-size: 25px;
      font-weight: 700;
      line-height: 33px;
      margin: 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }

    .top-right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .date-info,
    .left,
    .right {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
    }

    .date-info p,
    .left p,
    .right p {
      font-size: 12px;
      font-weight: 500;
    }

    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
      line-height: 22px;
    }

    hr {
      border: 1px solid rgba(0, 0, 0, 0.32);
      margin: 3px 0;
    }

    .description {
      margin-top: 50px;
    }

    .table {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }

    .col-desc,
    .col-taken,
    .col-number,
    .col-discount,
    .col-mva,
    .col-amount {
      text-align: center;
      padding: 6px;
      font-weight: 700;
    }

    .col-desc {
      text-align: left;
      font-weight: 700;
    }

    .table_data {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400 !important;
    }

    .col-desc_det {
      text-align: start;
      padding: 6px;
      font-weight: 400;
    }

    .col-taken_det,
    .col-number_det,
    .col-discount_det,
    .col-mva_det,
    .col-amount_det {
      text-align: center;
      padding: 6px;
      font-weight: 400;
    }

    .col-amount,
    .col-amount_det {
      text-align: end;
    }

    .eu-control {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr;
      font-size: 12px;
    }

    .left,
    .center,
    .right {
      text-align: center;
    }

    .table-summary {
      display: grid;
      grid-template-columns: 2fr 2fr;
      font-size: 12px;
    }

    .to-pay {
      display: flex;
      justify-content: space-between;
      padding: 6px;
    }

    .to-pay p {
      font-size: 14px;
      font-weight: 700;
    }

    .payment-section {
      padding: 0px;
    }

    .cash-invoice {
      font-size: 12px;
      font-weight: 500;
    }

    .payment-info {
      display: flex;
      align-items: center;
      margin-top: 0px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-right: 10px;
    }

    .blue-line {
      width: 100%;
      height: 15px;
      background: #ffc107;
    }

    .payment-details {
      display: flex;
      flex-direction: column;
      margin: 12px 0;
    }

    .payment-detail {
      display: flex;
      justify-content: flex-start;
    }

    .detail-label {
      font-size: 15px;
      font-weight: 500;
      width: 170px;
    }

    .detail-value {
      font-size: 15px;
      font-weight: 500;
    }

    .divider {
      border: 1px solid #000;
    }

    .company-info {
      font-size: 11.5px;
      font-weight: 500;
      margin-top: 0px;
    }

    .bolder {
      font-weight: 700;
    }

    .eu_control_div {
      padding: 3px 6px;
      text-align: center;
    }

    .eu_control_div p {
      line-height: 20px;
    }

    .eu_control_div:first-child {
      text-align: start;
    }

    .eu_control_div:last-child {
      text-align: end;
    }

    .table-summary_left {
      padding: 0 6px;
      line-height: 20px !important;
    }

    .table-summary_right {
      text-align: end;
      padding: 0 6px;
      line-height: 20px !important;
    }

    .eu-control p,
    .table-summary p {
      font-size: 12px;
      line-height: 18px;
    }

    .eu-control,
    .table-summary {
      padding: 5px 0;
    }

    .ribbin {
    position: absolute;
    top: 27px;
    left: -34px;
    transform: rotate(-45deg);
    width: 150px;
    text-align: center;
    }
    .paid {
  background: #2e7d32 !important;
  color: #fff !important;
}

.open {
  background: #00000014 !important;
  color: #000 !important;
}

.partially_paid {
  background: #0288d1 !important;
  color: #fff !important;
}

.overdue {
  background: #d32f2f !important;
  color: #fff !important;
}

.due_today {
  background: #e5c62b !important;
  color: #000 !important;
}
  </style>
</head>

<body>
  <div class="container">
    <div>
      <div class="content">
        <div style="width: 100%;">
          <div style="display: flex; justify-content: space-between;width: 100%;">
            <div>
              <h2>${t("CustomerCredits")}</h2>
              <div style="margin-top: 10px;">
             
                <div style="display: flex;">
                  <p style="width: 130px;">${t("CreditNumber")} :</p>
                  <p><b>${creditNoteData?.creditNumber}</b></p>
                </div>
                 <div style="display: flex;">
                  <p style="width: 130px;">${t("CreditRemaining")} :</p>
                  <p><b>&#8377; ${creditNoteData?.remainingAmount}</b></p>
                </div>
                <div style="display: flex;">
                  <p style="width: 130px;">${t("CreditDate")} :</p>
                  <p>${moment(creditNoteData?.creditDate).format('DD-MM-YYYY')}</p>
                </div>
               
              </div>
            </div>
            <div>
              <img src=${Logo} width="250" alt="Company Logo" />
            </div>
          </div>
          <div style="margin-top: 50px; display: flex;justify-content:space-between">
            <div style="width: 50%">
              <p><b>${creditNoteData?.organisation?.name || ""}</b></p>
              <p>${creditNoteData?.organisation?.address1 ? creditNoteData?.organisation?.address1 + ',' : ''}
                ${creditNoteData?.organisation?.address2 ? creditNoteData?.organisation?.address2 + ',' : ''}
                ${creditNoteData?.organisation?.city ? creditNoteData?.organisation?.city + ',' : ''}
                ${creditNoteData?.organisation?.state ? creditNoteData?.organisation?.state + ',' : ''}
                ${creditNoteData?.organisation?.country ? creditNoteData?.organisation?.country + ',' : ''}
                ${creditNoteData?.organisation?.zipcode ? creditNoteData?.organisation?.zipcode : ''}</p>
              <p>${creditNoteData?.organisation?.email ? creditNoteData?.organisation?.email : ''}</p>
              <p>VAT No. : ${creditNoteData?.organisation?.vatNumber}</p>
            </div>
            <div style="width: 50%;">
              <p><b>${t("Customer")}</b></p>
              <p>${creditNoteData?.customerId?.companyName || ""}</p>
              <p>${creditNoteData?.customerId?.shippingAddress?.address1 ? creditNoteData?.customerId?.shippingAddress?.address1 + ',' : ''}
                ${creditNoteData?.customerId?.shippingAddress?.address2 ? creditNoteData?.customerId?.shippingAddress?.address2 + ',' : ''}
                ${creditNoteData?.customerId?.shippingAddress?.city ? creditNoteData?.customerId?.shippingAddress?.city + ',' : ''}
                ${creditNoteData?.customerId?.shippingAddress?.state ? creditNoteData?.customerId?.shippingAddress?.state + ',' : ''}
                ${creditNoteData?.customerId?.shippingAddress?.zipcode ? creditNoteData?.customerId?.shippingAddress?.zipcode + ',' : ''}</p>
              <p>${creditNoteData?.venderId?.email ? creditNoteData?.venderId?.email : ''}</p>
              <p>${t("VATNo")} : ${creditNoteData?.customerId?.vatNumber}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <div class="table">
          <div style="width: 45%">
            <div class="col-desc">${t("Product")}</div>
          </div>
          <div style="width: 53%; display: flex; justify-content: space-between">
            <div class="col-taken" style="width: 80px">${t("Rate")}</div>
            <div class="col-discount" style="width: 90px">${t("Quantity")}</div>
            <div class="col-discount" style="width: 90px">${t("UOM")}</div>
            <div class="col-amount" style="width: 90px">${t("Amount")}</div>
          </div>
        </div>
        <hr style="border:1px solid #000;" />

        ${creditNoteData?.productId && creditNoteData?.productId.length > 0 && creditNoteData?.productId.map((data, index) => `
        <div class="table_data">
          <div style="width: 45%">
            <div class="col-desc_det">${data?.productId?.productName}</div>
          </div>
          <div style="width: 53%; display: flex; justify-content: space-between">
            <div class="col-taken_det" style="width: 80px">&#8377; ${data?.rate}</div>
            <div class="col-discount_det" style="width: 90px">${data?.quantity}</div>
            <div class="col-discount_det" style="width: 90px">${data?.productId?.uom}</div>
            <div class="col-amount_det" style="width: 90px">&#8377; ${parseFloat(data?.amount)}</div>
          </div>
        </div>
        `).join('')}

        <hr />
        <div style="display: flex; justify-content: space-between">
          <div class="" style="width: 43%">
          </div>
          <div style="width: 50%; margin-top: 10px;">
            <div class="table-summary">
              <div class="table-summary_left">
                <p>${t("Subtotal")}</p>
                    <p>${t("Discount")}</p>
                <p>${t("Adjustment")}</p>
                <p>${t("Total")}</p>
              </div >
              <div class="table-summary_right">
                <p>&#8377;${creditNoteData?.subTotal}</p>
                <p>- &#8377; ${creditNoteData?.discount}</p>
                <p>${creditNoteData.adjustmentType == 1 ? '+' : '-'} &#8377; ${creditNoteData?.adjustment}</p>
                <p>&#8377; ${creditNoteData?.total}</p>
                </div>
            </div >
            <hr style="border:1px solid #000" />
            <div class="to-pay">
              <p>${t("Credits Remaining")}</p>
              <p>&#8377; ${creditNoteData?.remainingAmount}</p>
            </div>
          </div >
        </div >
                <p> ${t("Note")} :-  ${creditNoteData?.note}</p>

      </div >
    </div >
  </div >
</body >

</html >
  ` }} />
      <Modal
        modalOpen={isModalOpen} // Indicates whether the modal is open or closed
        handelClick={statuschange} // Handler function to change the status of the modal
        handleClose={() => {
          setIsModalOpen({ open: false, id: "", currentComponent: "", para: '', head: '' })
        } // Handler function to close the modal and reset its state
        }
      />
    </>
  )
}

export default ViewCreditNote