import { Box, Checkbox, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BackButton, Button, Heading, Input, Modal } from '../../components/Common';
import { useLocation } from 'react-router';
import { customerCreate, customerDetail, customerUpdate } from '../../Service/customer.service';
import { useFormik } from 'formik'
import { addCustomerValidationSchema } from '../../helper/validation';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addCustomerInitialValues } from '../../helper/initialValues'

const AddCustomer = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState({});


  const formik = useFormik({
    initialValues: addCustomerInitialValues,
    onSubmit: async (value) => {

      const payload = {
        "name": value?.name,
        "companyName": value?.companyName,
        "email": value?.email,
        "mobile": value?.mobileNumber,
        'vatNumber': value?.vatNumber,
        "address": [
          {
            "address1": value?.addressLine1,
            "address2": value?.addressLine2,
            "country": value?.country,
            "id": value?.billingAddressId,
            "state": value?.state,
            "city": value?.city,
            "zipcode": value?.zipCode,
            "addressType": 2,
          }, {
            "address1": value?.sAddressLine1,
            "address2": value?.sAddressLine2,
            "id": value?.shippingAddressId,
            "country": value?.sCountry,
            "state": value?.sState,
            "city": value?.sCity,
            "zipcode": value?.sZipCode,
            "addressType": 1
          }
        ]
      }

      let res = isEdit?.isEdit ? await customerUpdate(isEdit?.id, payload) : await customerCreate(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || t('Success')
        }))
        formik.resetForm();
        navigate('/customer');
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || t('Something_went_wrong_Please_try_again_later')
        }
        ))
      }
    },
    validationSchema: addCustomerValidationSchema,
  });

  const getCustomerDetails = async (id) => {
    let res = await customerDetail(id);
    if (res?.status) {
      formik.setFieldValue('name', res.data?.name);
      formik.setFieldValue('companyName', res.data?.companyName);
      formik.setFieldValue('email', res.data?.email);
      formik.setFieldValue('mobileNumber', res.data?.mobile);
      formik.setFieldValue('addressLine1', res.data?.billingAddress?.address1);
      formik.setFieldValue('addressLine2', res.data?.billingAddress?.address2);
      formik.setFieldValue('billingAddressId', res.data?.billingAddress?.id);
      formik.setFieldValue('city', res.data?.billingAddress?.city);
      formik.setFieldValue('state', res.data?.billingAddress?.state);
      formik.setFieldValue('zipCode', res.data?.billingAddress?.zipcode);
      formik.setFieldValue('country', res.data?.billingAddress?.country);
      formik.setFieldValue('vatNumber', res.data?.vatNumber);
      formik.setFieldValue('sAddressLine1', res.data?.shippingAddress?.address1);
      formik.setFieldValue('shippingAddressId', res.data?.shippingAddress?.id);

      formik.setFieldValue('sAddressLine2', res.data?.shippingAddress?.address2);
      formik.setFieldValue('sCity', res.data?.shippingAddress?.city);
      formik.setFieldValue('sState', res.data?.shippingAddress?.state);
      formik.setFieldValue('sZipCode', res.data?.shippingAddress?.zipcode);
      formik.setFieldValue('sCountry', res.data?.shippingAddress?.country);
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || t('Something_went_wrong_Please_try_again_later')
      }
      ))
    }
  }

  useEffect(() => {
    if (state && state?.id) {
      setIsEdit({
        isEdit: state?.isEdit || false,
        id: state?.id
      });

      getCustomerDetails(state?.id);
    }
  }, [state]);




  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <BackButton />
          <Heading head={isEdit?.isEdit ? t('EditCustomer') : t('AddCustomer')} />
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xl={8}>
          <Box className='card_container' p={3}>
            <Grid container spacing={2} paddingY={1}>

              <Grid item xs={6}>
                <Input labelinput={t('Name')}
                  name='name'
                  formik={formik}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={6}>
                <Input labelinput={t('companyName')}
                  name='companyName'
                  formik={formik}
                  maxLength={50}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Input labelinput={t('Email')}
                  name='email'
                  formik={formik}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input labelinput={t('mobileNumber')}
                  name='mobileNumber'
                  formik={formik}
                  maxLength={12}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input labelinput={t('VatNumber')}
                  name='vatNumber'
                  formik={formik}
                  maxLength={15}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Heading smallHead={t('BillingAddress')} />
              </Grid>
              <Grid item xs={6}>
                <Input labelinput={t('Address_Line_1')}
                  name='addressLine1'
                  formik={formik}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6}>
                <Input labelinput={t('Address_Line_2')}
                  name='addressLine2'
                  formik={formik}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('Country')}
                  name='country'
                  formik={formik}
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('State')}
                  name='state'
                  formik={formik}
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('City')}
                  name='city'
                  formik={formik}
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('ZipCode')}
                  name='zipCode'
                  formik={formik}
                  maxLength={7}
                />
              </Grid>


              <Grid item xs={12} sx={{ mt: 2 }}>
                <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
                  <Heading smallHead={t('ShippingAddress')} />
                  <Stack flexDirection={'row'} gap={'5px'} alignItems={'center'}>
                    <Checkbox

                      name='sameAsBilling'
                      value={formik.values.sameAsBilling}
                      color='primary'
                      sx={{ padding: '0', '& .MuiSvgIcon-root': { fontSize: 20 } }}
                      onChange={(e) => {
                        formik.setFieldValue('sameAsBilling', e.target.checked);
                        if (e.target.checked) {
                          formik.setFieldValue('sAddressLine1', formik.values.addressLine1);
                          formik.setFieldValue('sAddressLine2', formik.values.addressLine2);
                          formik.setFieldValue('sCountry', formik.values.country);
                          formik.setFieldValue('sState', formik.values.state);
                          formik.setFieldValue('sCity', formik.values.city);
                          formik.setFieldValue('sZipCode', formik.values.zipCode);
                        } else {
                          formik.setFieldValue('sAddressLine1', '');
                          formik.setFieldValue('sAddressLine2', '');
                          formik.setFieldValue('sCountry', '');
                          formik.setFieldValue('sState', '');
                          formik.setFieldValue('sCity', '');
                          formik.setFieldValue('sZipCode', '');
                        }
                      }
                      }
                    />
                    <Typography fontSize={'13px'}> {t("SameasBillingAddress")}</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Input labelinput={t('Address_Line_1')}
                  name='sAddressLine1'
                  formik={formik}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6}>
                <Input labelinput={t('Address_Line_2')}
                  name='sAddressLine2'
                  formik={formik}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('Country')}
                  name='sCountry'
                  formik={formik}
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('State')}
                  name='sState'
                  formik={formik}
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('City')}
                  name='sCity'
                  formik={formik}
                  maxLength={30}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Input labelinput={t('ZipCode')}
                  name='sZipCode'
                  formik={formik}
                  maxLength={7}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack flexDirection={'row'} gap={1} mt={2} justifyContent={'flex-end'}>
                  <Button buttonName={t('Cancel')} size='small' color='white' />
                  <Button buttonName={isEdit?.isEdit ? t('Save') : t('Create')}
                    size='small' onClick={formik.handleSubmit} />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>

      </Grid>

    </>
  )
}

export default AddCustomer