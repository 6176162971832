import { deleteRequest, get, post, put } from "../web.request";

export const paymentOrderData = async (id) => {
  return await get(`/customerPaymentList${id}`);
}

export const addPaymentMade = async (data) => {
  return await post('/addBulkSalesPayment', data);
}

export const paymentList = async (query) => {
  return await get(`/salesPaymentList${query}`);
}

export const paymentDetail = async (id) => {
  return await get(`/salesPaymentDetail/${id}`);
}

export const salesOrderPayment = async (data) => {
  return await post(`/addSalesPayment`, data);
}

export const paymentPandingCustomer = async () => {
  return await get(`/paymentPendingCustomers`);
}