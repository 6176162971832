import { deleteRequest, get, post, put } from "../web.request";


export const addOrganization = async (id, data) => {
  return await post(`/addOrganisation/${id}`, data);
}

export const organisationDetail = async (query) => {
  return await get(`/organisationDetail/${query}`);
}
