import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, DateRange, Heading, Select } from '../../../components/Common';
import { customerDropdownForReport, salesInvoiceReport, salesInvoiceReportDownload } from '../../../Service/Report.service';
import { useTranslation } from 'react-i18next';

const RefundHistory = () => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState('SelectCustomer');
  const [customerData, setCustomerData] = useState([])
  const [fromData, setFromData] = useState('')
  const [toData, setToData] = useState('')
  const { t } = useTranslation();


  const handleChange = (e) => {
    setCustomer(e.target.value)
  }

  const getCustomer = async () => {
    const res = await customerDropdownForReport();
    if (res.status) {
      setCustomerData(res.data)
    }
    else {
      setCustomerData([])
    }
  }

  const getSalesInvoiceReport = async () => {
    const selectQry = `?customerId=${customer}&fromDate=${fromData}&toDate=${toData}`
    const res = await salesInvoiceReport(selectQry);
    if (res.status) {
      console.log(res.data)
    }
    else {
      console.log(res.message)
    }
  }
  console.log('SalesByCustomer', fromData, toData)

  useEffect(() => {
    getSalesInvoiceReport()
  }
    , [customer])



  useEffect(() => {
    getCustomer()
  }
    , [])

  return (
    <Box>
      <Stack mb={2}>
        <Heading head='Refund History' />
        <Stack flexDirection={'row'} justifyContent={'space-between'} mt={1}>
          <Stack flexDirection={'row'} gap={1}>
            {/* <Search /> */}
            <Select selectList={customerData} style={{ width: '200px' }} selectHead={t('None')} value={customer} onChange={(e) => handleChange(e)} />
            <DateRange
              fromDate={fromData}
              setFromDate={setFromData}
              toDate={toData}
              setToDate={setToData}
            />
          </Stack>
          <Button buttonName='Export Report' size='small' />
        </Stack>
      </Stack>
      <Stack>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Invoice Count</TableCell>
                <TableCell>Sales</TableCell>
                <TableCell>Sales with TAX</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>John Deo</TableCell>
                <TableCell>10</TableCell>
                <TableCell>10,000</TableCell>
                <TableCell>5% VAT</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>John Deo</TableCell>
                <TableCell>10</TableCell>
                <TableCell>10,000</TableCell>
                <TableCell>5% VAT</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>John Deo</TableCell>
                <TableCell>10</TableCell>
                <TableCell>10,000</TableCell>
                <TableCell>5% VAT</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>John Deo</TableCell>
                <TableCell>10</TableCell>
                <TableCell>10,000</TableCell>
                <TableCell>5% VAT</TableCell>
              </TableRow>
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell>5</TableCell>
                <TableCell>10,000</TableCell>
                <TableCell>10,000</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  )
}

export default RefundHistory