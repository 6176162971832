import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { BackButton, Button, Heading, Input, } from '../../components/Common'
import { FaRegUser } from 'react-icons/fa';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { AdminDetail } from '../../Service/Admin.service';
import { organizationUpdateValidationSchema } from '../../helper/validation';
import { organizationInitialValues } from '../../helper/initialValues';
import { useFormik } from 'formik';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { addOrganization, organisationDetail } from '../../Service/Organization.service';
const Organization = () => {
  const [edit, setEdit] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => setEdit((show) => !show);

  const [file, setFile] = useState({});

  function handleChange(e) {
    setFile({ image: URL.createObjectURL(e.target.files[0]), images: e.target.files[0] });
    // convert e.target.files to  base 64 string
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFile({ image: reader.result, images: e.target.files[0] });
    };
  }


  const getProfiledata = async (id) => {
    const res = await organisationDetail(id)
    if (res?.status) {
      formik.setValues({
        ...formik.values,
        name: res.data?.name,
        vatNumber: res.data?.vatNumber,
        email: res.data?.email,
        phone: res.data?.mobileNo,
        profileImage: res.data?.logo,
        address1: res.data?.address1,
        address2: res.data?.address2,
        country: res.data?.country,
        state: res.data?.state,
        city: res.data?.city,
        zipCode: res.data?.zipcode,
      })

      setFile({ image: res.data?.logo, id: res.data?.id })

    } else {
      console.log('error')
    }
  }

  useEffect(() => {
    getProfiledata(1)
  }, [])



  const formik = useFormik({
    initialValues: organizationInitialValues,
    onSubmit: async (value) => {
      const formData = new FormData();
      formData.append('name', value.name);
      formData.append('vatNumber', value.vatNumber);
      formData.append('email', value.email);
      formData.append('address1', value.address1);
      formData.append('address2', value.address2);
      formData.append('country', value.country);
      formData.append('state', value.state);
      formData.append('city', value.city);
      formData.append('mobileNo', value.phone);
      formData.append('zipcode', value.zipCode);

      if (file && !file.id) {
        formData.append('logo', file.images);
      }

      const res = await addOrganization(1, formData);
      if (res.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || t('Success')
        }))

        getProfiledata(1)
        handleClick();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || t('Error')
        }))
      }
    },
    validationSchema: organizationUpdateValidationSchema,
  });


  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
        <BackButton />
        <Heading head={t('Organization')} />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className='card_container'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container sx={{ width: { xs: '100%', sm: '100%' } }}>
                  <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    {
                      !edit ?
                        <Box sx={{ width: '110px', height: '110px', background: '#f1f1f1', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {
                            file ?
                              <img src={file.image} width={110} height={110} alt='' style={{ borderRadius: '50%' }} />
                              :
                              <FaRegUser style={{ fontSize: '50px', color: '#00000091' }} />
                          }
                        </Box>
                        :
                        <Box sx={{ width: '110px', height: '110px', background: '#f1f1f1', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                          {
                            file ?
                              <img src={file.image} width={110} height={110} alt='' style={{ borderRadius: '50%' }} />
                              :
                              <FaRegUser style={{ fontSize: '50px', color: '#00000091' }} />
                          }
                          <Box sx={{ position: 'absolute', cursor: 'pointer', bottom: '14px', right: '0px', borderRadius: '50%' }}>
                            <Box sx={{ position: 'relative', width: '22px', height: '22px' }}>
                              <MdOutlineModeEditOutline style={{ position: 'relative', cursor: 'pointer', background: '#ffc107', color: '#fff', fontSize: '22px', padding: '4px', borderRadius: '50%' }} />
                              <input type="file" onChange={handleChange} style={{ position: 'absolute', opacity: '0', width: '100%', height: '100%', cursor: 'pointer', right: '0' }} />
                            </Box>
                          </Box>
                        </Box>
                    }
                  </Grid>
                  <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={1}>
                    <Typography fontSize={'18px'} fontWeight={500}>{formik.values.name} </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ my: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Input labelinput={t('OrganizationName')}
                      variant='standard'
                      name='name'
                      maxLength={100}
                      formik={formik}
                      disabled={edit ? false : true} />
                  </Grid>

                  <Grid item xs={6}>
                    <Input labelinput={t('Email')}
                      variant='standard'
                      name='email'
                      formik={formik}
                      disabled={edit ? false : true}
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input labelinput={t('Phone')}
                      variant='standard'
                      name='phone'
                      formik={formik}
                      maxLength={10}
                      disabled={edit ? false : true} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input labelinput={t('VatNumber')}
                      variant='standard'
                      name='vatnumber'
                      formik={formik}
                      maxLength={100}
                      disabled={edit ? false : true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input labelinput={t('Address_Line_1')}
                      variant='standard'
                      name='address1'
                      formik={formik}
                      maxLength={150}
                      disabled={edit ? false : true} />
                  </Grid>
                  <Grid item xs={12}>
                    <Input labelinput={t('Address_Line_2')}
                      variant='standard'
                      name='address2'
                      formik={formik}
                      maxLength={150}
                      disabled={edit ? false : true} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input labelinput={t('Country')}
                      variant='standard'
                      name='country'
                      formik={formik}
                      maxLength={20}
                      disabled={edit ? false : true} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input labelinput={t('State')}
                      variant='standard'
                      name='state'
                      formik={formik}
                      maxLength={20}
                      disabled={edit ? false : true} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input labelinput={t('City')}
                      variant='standard'
                      name='city'
                      formik={formik}
                      maxLength={20}
                      disabled={edit ? false : true} />
                  </Grid>
                  <Grid item xs={6}>
                    <Input labelinput={t('ZipCode')}
                      variant='standard'
                      name='zipCode'
                      formik={formik}
                      maxLength={6}
                      disabled={edit ? false : true} />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack gap={1} flexDirection={'row'} justifyContent={'flex-end'}>
                  {
                    edit ?
                      <>
                        <Button buttonName={t('Cancle')} onClick={handleClick} />
                        <Button buttonName={t('Save')} onClick={formik.handleSubmit} />
                      </>
                      :
                      <Button buttonName={t('Edit')} onClick={handleClick} />
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>

      </Grid>
    </>
  )
}

export default Organization