import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Stack } from "@mui/material";
import { FaRegCalendar } from "react-icons/fa6";


export default function DateRange(
    { fromDate, setFromDate, toDate, setToDate }
) {

    const range = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
            moment()
                .subtract(1, "month")
                .startOf("month"),
            moment()
                .subtract(1, "month")
                .endOf("month")
        ],
        "Last Year": [
            moment()
                .subtract(1, "year")
                .startOf("year"),
            moment()
                .subtract(1, "year")
                .endOf("year")
        ]
    };
    const [date, setDate] = useState();

    const handleEvent = (event, picker) => {


        // Create a new Date object from the date string
        // let date = new Date(picker.startDate._d);

        // // Add one day to the date
        // date.setDate(date.getDate() + 1);

        // // Convert the new date to ISO string format
        // let newISOString = date.toISOString();
        setFromDate(picker.startDate._d.toISOString());

        setToDate(picker.endDate._d.toISOString());
    };

    return (
        <div className="date_range">
            <DateRangePicker
                initialSettings={{
                    ranges: range,
                }}
                alwaysShowCalendars={true}
                onEvent={handleEvent}
            >
                <button>
                    <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                        {/* <div className="date_range_icon" style={{ background: '#00BFF520', borderRadius: '5px' }}><FaRegCalendar /></div> */}
                        <div style={{ lineHeight: 'normal', whiteSpace: 'nowrap' }}>
                            {!fromDate ? `Select Date` :
                                `${moment(fromDate).format("L")} to ${moment(toDate).format("L")}`}
                        </div>
                    </Stack>
                </button>
            </DateRangePicker>
        </div>
    );
}