import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, Input } from '../../Common'
import { changePasswordInitialValues } from '../../../helper/initialValues';
import { changePasswordValidationSchema } from '../../../helper/validation';
import { useFormik } from 'formik';
import { notificationSuccess } from '../../../state/Action/NotificationAction';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../../Service/Profile.service';
import { logoutApi } from '../../../state/Action/authAction';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const ChangePassword = ({ handleClose, modalOpen }) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { t } = useTranslation();


    const formik = useFormik({
        initialValues: changePasswordInitialValues,
        onSubmit: async (value) => {
            const payload = {
                oldPassword: value.currentPassword,
                newPassword: value.newPassword
            };
            if (value.newPassword == value.confirmpass) {
                const res = await changePassword(modalOpen.id, payload);
                if (res.status) {
                    handleClose();
                    dispatch(notificationSuccess({
                        show: true,
                        type: 'success',
                        message: res.message || t('Success')
                    }))
                    localStorage.removeItem('token');
                    dispatch(logoutApi());

                    Navigate('/');

                } else {
                    dispatch(notificationSuccess({
                        show: true,
                        type: 'error',
                        message: res.message || t('Error')
                    }))
                }
            } else {
                dispatch(notificationSuccess({
                    show: true,
                    type: 'error',
                    message: t('Password_and_confirm_password_does_not_match')
                }))
            }

        },
        validationSchema: changePasswordValidationSchema,
    });
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {t("ChangePassword")}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Input
                            labelinput={t('CurrentPassword')}
                            passwordinput
                            formik={formik}
                            name='currentPassword'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            labelinput={t('NewPassword')}
                            passwordinput
                            formik={formik}
                            name='newPassword'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            labelinput={t('ConfirmPassword')}
                            passwordinput
                            formik={formik}
                            name='confirmpass'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={t('Cancel')} size='small' color='white' onClick={handleClose} />
                <Button buttonName={t('Save')} size='small' onClick={formik.handleSubmit} />
            </DialogActions>
        </>
    )
}

export default ChangePassword