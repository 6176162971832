import { deleteRequest, get, post, put } from "../web.request";

export const WareHouseList = async (query) => {
  return await get(`/warehouseList${query}`)
}

export const WareHouseCreate = async (data) => {
  return await post(`/addWarehouse`, data);
}

export const WareHouseUpdate = async (id, data) => {
  return await put(`/updateWarehouse/${id}`, data);
}

export const WareHouseDetail = async (id) => {
  return await get(`/warehouseDetail/${id}`);
}

export const WareHouseDelete = async (id) => {
  return await deleteRequest(`/deleteWarehouse/${id}`);
}

export const WareHouseStatusChange = async (id) => {
  return await put(`/statusChangeWarehouse/${id}`);
}

