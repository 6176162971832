import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button } from '..'
import { useTranslation } from 'react-i18next'

const Delete = ({ handleClose, modalOpen, handelClick }) => {
    const { t } = useTranslation();

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.head}
            </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box width={{ sm: '400px' }}>
                    <Typography fontSize={'18px'}>{modalOpen.para}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button buttonName={t('No')} size='small' color='white' onClick={handleClose} />
                <Button buttonName={t('Yes')} size='small' onClick={handelClick} />
            </DialogActions>
        </>
    )
}

export default Delete