import { deleteRequest, get, post, put } from "../web.request";


export const AdjustMentDetail = async (id) => {
  return await get(`/adjustmentDetail/${id}`);
}

export const AdjustMentList = async (query) => {
  return await get(`/adjustmentList${query}`)
}

export const AdjustMentCreate = async (data) => {
  return await post(`/addAdjustment `, data);
}

export const AdjustMentUpdate = async (id, data) => {
  return await put(`/updateAdjustment/${id}`, data);
}

export const AdjustMentQtyFind = async () => {
  return await get(`/findProductQuantity`);
}


export const statusChangeDrafttoConfirm = async (id) => {
  return await put(`/statusChangeAdjustment/${id}`);
}

