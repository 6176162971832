import React, { useState } from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton, Box } from '@mui/material';
import { CloseIcon } from '../../helper/Icons'
import { Autocomplete, Button, Select } from '../../components/Common';
import { statusFilter } from '../../helper/Helper';
import { useTranslation } from 'react-i18next';

const InvoiceFilter = ({ openDrawer, customerData, setValue, toggleDrawer, formik, }) => {
  const drawerWidth = 250;
  const { t } = useTranslation();

  return (
    <>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2 },
        }}
      >
        <Box height={'100%'}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={'18px'}>{t("Filter")}</Typography>
              <IconButton
                aria-label="close"
                onClick={toggleDrawer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Grid>
            <Grid item xs={12} marginY={'5px'}>
              <Divider sx={{ borderColor: "gray.main", }} />
            </Grid>


            <Grid item xs={12} marginY={2}>
              <Grid item xs={12}>

                <Autocomplete
                  data={customerData}
                  labelinput={t('Customer')}
                  onChange={(e, val) => {
                    if (val) {
                      formik.setFieldValue('customer', val);
                      formik.setFieldValue('customerId', val.id);
                      formik.touched.product = false;
                    } else {
                      formik.setFieldValue('customer', '');
                      formik.setFieldValue('customerId', '');
                    }
                  }}
                  name='customerId'
                  name1='customer'
                  formik={formik}
                  value={formik.values.customer}
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  labelinput={t('Status')}
                  selectHead={t('None')}
                  name='status'
                  value={formik.values.status}
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue('status', e.target.value);
                  }}
                  selectList={[{
                    id: 0,
                    name: t('Open')
                  }, {
                    id: '1',
                    name: t('DueToday')
                  },
                  {
                    id: 2,
                    name: t('Overdue')
                  }, {
                    id: 3,
                    name: t('PartiallyPaid')
                  },
                  {
                    id: 4,
                    name: t('Paid')
                  }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Stack flexDirection={'row'} gap={1}>
          <Button buttonName={t('Clear')} color='white' style={{ width: '100%' }} size='small' onClick={() => {
            formik.resetForm();
            setValue([]);
            toggleDrawer();
            formik.handleSubmit();
          }} />
          <Button buttonName={t('Filter')} style={{ width: '100%' }} size='small' onClick={formik.handleSubmit} />
        </Stack>
      </Drawer>
    </>
  )
}

export default InvoiceFilter