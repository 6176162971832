import { deleteRequest, get, post, put } from "../web.request";


export const customerDropdown = async () => {
  return await get(`/customerDropdown`);
}

export const customerList = async (query) => {
  return await get(`/customerList${query}`);
}

export const changeCustomerStatus = async (id) => {
  return await put(`/statusChangeCustomer/${id}`);
}

export const deleteCustomer = async (id) => {
  return await deleteRequest(`/deleteCustomer/${id}`);
}

export const customerDetail = async (id) => {
  return await get(`/customerDetail/${id}`);
}

export const customerUpdate = async (id, data) => {
  return await put(`/updateCustomer/${id}`, data);
}

export const customerCreate = async (data) => {
  return await post(`/addCustomer`, data);
}

export const ledgerUpdate = async (id, data) => {
  return await put(`/ledgerUpdate/${id}`, data);
}

